@import "@Core/assets/styles/abstracts";

.ReadAll {
  position: fixed;
  bottom: 15px;

  left: 50%;
  z-index: 90;

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 30px);

  max-width: 850px;
  padding: px(2);
  overflow: hidden;

  background-color: color(white);
  border: 2px solid color(white);
  border-radius: $border-radius;

  box-shadow: $box-shadow;
  transform: translateX(-50%);

  @include transition(opacity);

  > div {
    display: flex;
    flex-grow: 1;
    margin: 0 px(3);

    > div {
      width: 100%;
    }
  }
}

.Visible {
  opacity: 1;
}

.Hidden {
  opacity: 0;
  user-select: none;
  pointer-events: none;
}

.Title {
  color: color(primary);
}

@include media-breakpoint-only(xs) {
  .ReadAll {
    flex-direction: column;

    > div {
      width: 100%;
      margin: px(2) 0;
    }
  }
}
