@import "@Core/assets/styles/abstracts";

.Inputs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-bottom: px(-2);

  .Statement {
    margin-right: px(2);
    margin-bottom: px(2);

    background-color: color(white, 200);

    border: 2px solid color(white, 200);
    border-radius: $border-radius;

    @include transition(border-color);

    &:hover {
      border-color: color(primary);
    }

    label {
      display: flex;
      align-items: center;
      padding: 6px px(2) 4px px(2);

      color: color(primary);

      &::before {
        display: none;
      }

      &::after {
        top: -2px;
        right: -2px;
        bottom: -2px;
        left: -2px;

        width: calc(100% + 4px);
        height: calc(100% + 4px);

        border: 2px solid color(primary);

        border-radius: $border-radius;

        content: "";
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
