@import "@Core/assets/styles/abstracts";

.Inputs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: px(-4);

  .Emoji {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 107px;
    max-width: 107px;
    height: 94px;
    margin-right: px(2);
    margin-bottom: px(4);
    padding: 0;

    background-color: color(primary, 500);
    border: 2px solid color(primary, 500);
    border-radius: $border-radius;

    @include transition(border-color);

    &:hover {
      border-color: color(primary, 400);
    }

    &::before {
      display: none;
    }

    &::after {
      top: -2px;
      right: -2px;
      bottom: -2px;
      left: -2px;

      width: calc(100% + 4px);
      height: calc(100% + 4px);

      border: 2px solid color(primary);

      border-radius: $border-radius;

      content: "";
    }

    .Icon {
      width: 32px;
      height: 32px;
      margin-bottom: px(1.5);

      font-size: 32px;
    }

    .Text {
      color: color(primary);
    }
  }
}
