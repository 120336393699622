@import "@Core/assets/styles/abstracts";

.Item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 14px;

  cursor: pointer;

  &:hover {
    .Button {
      i {
        transform: translateX(2px);
      }
    }
  }
}

.Name {
  color: color(support);
  font-weight: $font-weight-light;
}

.Name,
.Button {
  font-size: $font-size-sm;
}

.Button {
  padding: 0;

  i {
    margin-left: px(1);

    @include transition(transform, lg);
  }
}
