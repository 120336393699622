@import "@Core/assets/styles/abstracts";

.Wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  footer {
    margin-top: auto;
  }
}

.Loader {
  height: 50px;

  svg > circle {
    stroke: color(secondary);
  }
}

.Introduction {
  margin-top: px(6);

  .Image {
    margin-bottom: px(3);

    img {
      max-width: 100%;
      max-height: 200px;
    }
  }

  .Step {
    max-width: 570px;
    margin: 0 auto;

    > *:last-child {
      margin-bottom: 0;
    }
  }
}
