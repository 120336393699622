@import "@Core/assets/styles/abstracts";

.Insight {
	display: grid;
	grid-template-columns: 1;
	grid-template-columns: 1;

	> * {
		grid-column: 1;
		grid-row: 1;
	}

	.Overlay {
		padding-top: px(2);
		padding-bottom: px(2);
		margin-left: -1px;
		margin-right: -1px;

		display: flex;
		z-index: 20;
		align-items: center;
		background-color: rgba(255, 255, 255, .5);
	}
}