@import "@Core/assets/styles/abstracts";

.Wrapper {
  position: relative;

  display: flex;
  flex-direction: column;

  width: 100%;
  min-height: 100vh;
  margin: 0 auto;

  footer {
    margin-top: auto;
  }
}

.Block {
  position: relative;
  z-index: 1;

  display: flex;
  width: 100%;
  max-width: 730px;
  margin: 0 auto;

  margin-bottom: px(8);

  padding: px(5) px(3);

  background-color: color(white);
  border-bottom-right-radius: $border-radius;
  border-bottom-left-radius: $border-radius;

  .BlockInner {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 460px;
    margin: 0 auto;
  }

  svg {
    z-index: -1;
  }
}

.Header {
  .Logo {
    img {
      max-height: 40px;
    }
  }
}

.Body {
  padding-top: px(12);

  @include media-breakpoint-down(md) {
    padding-top: px(8);
    padding-bottom: px(4);
  }

  .BodyHeader {
    margin-bottom: px(5);

    h1 {
      margin-bottom: 0;
    }

    p {
      margin-top: px(2);
      margin-bottom: 0;

      color: color(support);
      font-size: $font-size-md;
    }
  }

  .BodyContent {
    width: 100%;
    max-width: 360px;
  }

  .BodyFooter {
    margin-top: px(20);

    @include media-breakpoint-down(md) {
      margin-top: px(10);
    }

    .BodyFooterLogo {
      margin-bottom: px(2);
    }

    a {
      display: flex;
      align-items: center;

      color: color(primary);
      font-size: $font-size-sm;
      line-height: 24px;

      i {
        margin-right: px(2);
      }
    }
  }
}
