@import "@Core/assets/styles/abstracts";

.ArticlesDetailFooter {
  .Share {
    padding: px(10) 0;
  }

  .Related {
    padding-top: px(5);
    padding-bottom: px(5);
    background-color: color(primary, 500);

    .Article {
      margin-bottom: px(4);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
