@import "@Core/assets/styles/abstracts";

.Steps {
  position: absolute;
  top: 30px;
  right: px(4);

  @include media-breakpoint-down(md) {
    position: relative;
    top: 0;
    left: 0;

    margin-bottom: px(2);
  }
}

.Completed {
  margin-bottom: px(5);

  background-color: color(primary, 500);
  border-color: color(primary, 500);
  box-shadow: none;

  h2 {
    margin-bottom: px(2);

    font-size: $font-size-h1;
  }

  p {
    font-size: $font-size-md;
  }
}

.Introduction {
  margin-bottom: px(5);

  > *:last-child {
    margin-bottom: 0;
  }
}

.Garlands {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;

  width: 100%;

  user-select: none;
  pointer-events: none;
}
