@each $color, $tint in $theme-colors {
  @each $i, $value in $tint {
    @if ($i == "default") {
      .color-#{$color} {
        color: $value !important;
      }

      .background-#{$color} {
        background-color: $value !important;
      }
    } @else {
      .color-#{$color}-#{$i} {
        color: $value !important;
      }

      .background-#{$color}-#{$i} {
        background-color: $value !important;
      }
    }
  }
}
