@import "@Core/assets/styles/abstracts";

.QuestionnaireCompleted {
  display: flex;

  .Content {
    margin-right: auto;

    > *:last-child {
      margin-bottom: 0;
    }
  }

  .Image {
    padding-left: px(3);

    img {
      min-width: 120px;
      max-width: 200px;
    }
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column-reverse;

    .Image {
      padding-left: 0;

      img {
        display: block;
        max-width: 200px;
        margin: 0 auto px(3) auto;
      }
    }
  }
}
