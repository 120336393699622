@import "@Core/assets/styles/abstracts";

.Profile {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	height: 100%;
	border-radius: 50%;

	background-color: color(white);
	box-shadow: 0 4px 6px 1px rgba(13, 38, 53, 0.06), 0 0 1px 1px rgba(13, 38, 53, 0.02);
	border: 1px solid color(support, light);

	font-size: $font-size-xs;
	font-weight: $font-weight-medium;

	img {
		margin-bottom: px(.75);
	}

	&.hasClick:hover {
		cursor: pointer;
		text-decoration: underline;
	}
}