body {
  color: $body-color;
  font-weight: $font-weight-base;
  font-size: $font-size-base;
  font-family: $font-family-base;
  line-height: $line-height-base;
}

// Headings
.hero {
  font-size: $font-size-xl;
  line-height: 60px;

  &--small {
    font-size: $font-size-lg;
    line-height: 34px;
  }

  &--large {
    font-size: 32px;
    line-height: 34px;
  }
}

@include media-breakpoint-down(sm) {
  .hero {
    font-size: $font-size-lg;
    line-height: $headings-line-height;
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin: 0 0 $headings-margin-bottom;

  color: $headings-color;
  font-weight: $headings-font-weight;
  font-family: $headings-font-family;
  line-height: $headings-line-height;
}

.h1,
h1 {
  font-size: $font-size-h1;
}

.h2,
h2 {
  font-size: $font-size-h2;
}

.h3,
h3 {
  font-size: $font-size-h3;
}

.h4,
h4 {
  font-size: $font-size-h4;
}

.h5,
h5 {
	font-size: $font-size-h5;

	&.subtitle {
		font-weight: $font-weight-regular;
		color: color(support);
		text-transform: uppercase;
	}
}

.h6,
h6 {
  font-size: $font-size-h6;
}

// Texts
p {
  margin-top: 0;
  margin-bottom: $paragraph-margin;

  &.lead {
    margin-bottom: $paragraph-lead-margin-bottom;

    font-size: $paragraph-lead-font-size;
    line-height: 1.8;
  }

  &.small {
    font-size: $font-size-sm;
  }
}

strong,
b {
  font-weight: $font-weight-bold;
}

a {
  color: $link-color;
  text-decoration: $link-decoration;

  @include transition(color);

  &:hover {
    text-decoration: $link-hover-decoration;
  }
}

// Lists
ul,
ol {
  margin-top: px(0.5);
  margin-bottom: $list-margin;

  padding-left: 0;

  list-style: none;

  li {
    position: relative;

    margin-bottom: $list-item-margin;
    padding-left: 20px;

    ul,
    ol {
      margin-top: $list-item-margin;
      margin-bottom: 0;
    }

    &::before {
      position: absolute;
      left: 0;

      display: block;
    }
  }
}

ul {
  li {
    &::before {
      top: 8px;

      width: 8px;
      height: 8px;

      background-color: color(quaternary, light);
      border: 1px solid transparent;
      border-radius: 50%;

      transform: translateY(-2px);

      content: "";
    }

    ul li::before {
      background: transparent;
      border-color: color(primary);
    }
  }
}

ol {
  counter-reset: counter;

  > li {
    margin-bottom: px(2);
    padding-top: 2px;
    padding-left: px(4);

    counter-increment: counter;

    &::before {
      top: 0;

      width: 24px;
      height: 24px;

      color: color(primary);
      font-weight: $font-weight-bold;
      line-height: 27px;
      text-align: center;

      background-color: color(primary, 400);
      border-radius: 24px;

      content: counter(counter);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

// Blockquote
blockquote {
  margin: px(3) px(2) !important;

  color: color(primary);
  font-size: $font-size-h3;

  quotes: "\201C""\201D""\2018""\2019";

  p {
    margin: 0 0 px(3) 0;

    font-weight: bold;
    line-height: 40px;

    &::before,
    &::after {
      display: inline;
    }

    &::before {
      content: open-quote;
    }

    &::after {
      content: close-quote;
    }
  }
}

// Abbreviation
abbr {
  position: relative;

  display: inline-block;

  // Text
  > span:nth-child(1) {
    position: relative;

    cursor: pointer;

    // Icon
    > span {
      position: relative;

      display: inline-block;
      width: 17px;
      height: 17px;

      color: color(primary);

      font-weight: bold;
      font-size: 7px;

      font-family: "Times New Roman", Times, serif;
      line-height: 18px;
      text-align: center;

      background-color: color(primary, 400);
      border-radius: 17px;
      transform: translateY(-2px);

      &::after {
        position: absolute;
        top: 4px;
        left: 4px;

        display: block;
        width: 9px;
        height: 9px;

        border: 1px solid color(primary);
        border-radius: 9px;

        content: "";
      }
    }

    &::after {
      position: absolute;
      right: 20px;
      bottom: 0;
      left: 0;

      display: block;
      height: 2px;

      background-color: color(primary, 400);

      content: "";

      @include transition(background-color);
    }
  }

  // Popup
  > span:nth-child(2) {
    position: absolute;
    bottom: px(0.5);
    left: 100%;
    z-index: 15;

    min-width: 210px;
    max-width: 300px;
    margin-left: px(0.5);
    padding: px(2);

    background-color: color(white);
    border-radius: 2px;
    box-shadow: shadow();
    transform: translateY(-10px);
    visibility: hidden;
    opacity: 0;

    transition-property: opacity, transform;

    @include transition(all);

    // Title
    > span:nth-child(1) {
      display: block;
      margin-bottom: px(0.5);
      overflow: hidden;

      color: color(primary);
      font-weight: $headings-font-weight;

      white-space: nowrap;
      text-overflow: ellipsis;

      // Icon
      > span {
        position: relative;

        display: inline-block;
        width: 17px;
        height: 17px;

        color: color(primary);

        font-weight: bold;
        font-size: 7px;

        font-family: "Times New Roman", Times, serif;
        line-height: 18px;
        text-align: center;

        background-color: color(primary, 400);
        border-radius: 17px;
        transform: translateY(-3px);

        &::after {
          position: absolute;
          top: 4px;
          left: 4px;

          display: block;
          width: 9px;
          height: 9px;

          border: 1px solid color(primary);
          border-radius: 9px;

          content: "";
        }
      }
    }

    // Content
    > span:nth-child(2) {
      margin-bottom: 0 !important;

      font-size: $font-size-sm;
      line-height: 0.5;
    }
  }

  &:hover {
    span:nth-child(2) {
      transform: translateY(0);
      visibility: visible;
      opacity: 1;
    }
  }
}

@include media-breakpoint-down(sm) {
  abbr {
    position: static;

    > span:nth-child(2) {
      right: 15px;
      bottom: unset;
      left: 15px;

      width: unset;
      margin-top: 24px;
      margin-left: 0;
    }
  }
}

// Others
hr {
  height: 1px;
  margin-top: $hr-height * 0.5;
  margin-bottom: $hr-height * 0.5;

  background: color(support, light);
  border: 0;
}

.seperator {
  margin-top: $hr-height * 0.5;
  margin-bottom: $hr-height * 0.5;

  border: 0;

  &--line {
    height: 1px;

    background: color(support, light);
  }
}
