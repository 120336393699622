@import "@Core/assets/styles/abstracts";

.Field {
  position: relative;
}

.Input:focus + .Icon {
  opacity: 1;
}

.Inputs {
  position: relative;

  input {
    color: color(primary);
    font-size: $font-size-base;

    background-color: color(primary, 500);
    border: 2px solid color(primary, 500);
    border-radius: $border-radius;

    @include transition(border-color);

    &:focus,
    &:hover {
      border-color: color(primary, 400);
    }
  }

  .Icon {
    position: absolute;
    top: 50%;
    right: px(2);

    transform: translateY(-50%);

    user-select: none;
    pointer-events: none;
  }
}
