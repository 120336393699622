fieldset {
  margin: 15px 0;
  padding: 15px;

  font-size: $input-label-font-size;

  border: 1px solid $input-border-color;
  border-radius: 5px;

  legend {
    padding: 0 5px;
  }
}

label {
  display: block;
  padding-bottom: 5px;

  color: color(support);

  cursor: pointer;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  margin: 0;

  -webkit-appearance: none;
}

input[type="text"],
input[type="email"],
input[type="number"],
input[type="password"],
select,
textarea {
  display: block;
  width: 100%;
  padding: 15px px(2) 12px px(2);

  color: color(support, dark);

  font-size: $input-font-size;
  font-family: $font-family-base;

  background: $input-background-color;
  border: 1px solid $input-border-color;
  border-radius: $input-radius;

  appearance: none;

  &::placeholder {
    color: $input-placeholder-color;
    font-weight: $font-weight-regular;

    opacity: 0.7;
  }

  &:focus {
    border-color: $input-active-color;
    outline: none;
  }

  &:disabled {
    color: color(support);
  }
}

input[type="checkbox"],
input[type="radio"] {
  display: none;

  & + label {
    position: relative;

    padding-bottom: 0;
    padding-left: 25px;

    user-select: none;

    &::before {
      position: absolute;
      top: 2px;
      left: 0px;

      width: 16px;
      height: 16px;

      background: $input-background-color;
      border: 1px solid $input-border-color;
      border-radius: 4px;

      content: "";
    }

    &::after {
      position: absolute;
      top: 2px;
      left: 0px;

      width: 17px;
      height: 16px;

      color: color(secondary);
      font-size: 9px;

      font-family: $font-family-icons;
      line-height: 16px;
      text-align: center;

      opacity: 0;

      content: "\f00c";

      @include transition(opacity);
    }
  }

  &:checked + label {
    &::after {
      opacity: 1;
    }
  }
}

input[type="radio"] {
  & + label {
    position: relative;

    padding-bottom: 0;
    padding-left: 25px;

    &::before {
      border-radius: 100%;
    }

    &::after {
      border-radius: 100%;
    }
  }
}

select {
  position: relative;

  padding-right: 30px;

  &::after {
    position: absolute;
    top: 50%;
    right: 20px;

    font-size: 16px;
    font-family: "Font awesome 5 Pro";

    transform: translate(0, -50%);

    content: "\f078";
    pointer-events: none;
  }

  select:hover {
    cursor: pointer;
  }

  select:disabled {
    opacity: 0.5;
  }
}

textarea {
  display: block;
  width: 100%;
  min-height: 100px;

  line-height: 1.4;

  appearance: none;
  resize: none;

  &::placeholder {
    color: $input-placeholder-color;
  }

  &:focus {
    border-color: $input-active-color;
    outline: none;
  }
}

textarea {
  display: block;
  width: 100%;
  min-height: 100px;

  line-height: 1.4;

  appearance: none;
  resize: none;

  &::placeholder {
    color: $input-placeholder-color;
  }

  &:focus {
    border-color: $input-active-color;
    outline: none;
  }
}

.react-datepicker {
  font-family: $font-family-sans-serif !important;

  border-color: $input-border-color !important;
  border-radius: $input-radius !important;

  &-wrapper {
    width: 100%;
  }

  &-popper {
    z-index: 9999 !important;
  }

  &__triangle {
    display: none;
  }

  &__header {
    background-color: $input-background-color !important;
    border-bottom-color: $input-border-color !important;
    border-radius: $input-radius !important;

    &__dropdown {
      display: flex;
      padding: 0 12px;

      > div {
        flex-grow: 1;

        select {
          padding: px(0.5) px(1);

          font-size: 13px;

          background-color: color(white);
        }
      }
    }
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background-color: color(primary);
  }
}

.rc-slider {
  position: relative;

  box-sizing: border-box;
  height: 60px;
  margin-right: 10px;

  margin-left: 10px;
  padding-top: 16px;

  touch-action: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &-rail {
    position: absolute;

    width: 100%;
    height: 2px;

    background-color: #e9e9e9;
    border-radius: 6px;
  }

  &-track {
    position: absolute;
    left: 0;
    z-index: 1;

    height: 2px;

    background-color: color(primary);
    border-radius: 6px;

    @include transition(width, lg);
  }

  &-handle {
    position: absolute;

    width: 32px;
    height: 32px;

    margin-top: -16px;

    vertical-align: middle;

    background-color: color(primary);
    border-radius: 50%;
    cursor: pointer;

    cursor: pointer;
    cursor: -webkit-grab;
    cursor: grab;

    touch-action: pan-x;
  }

  &-handle-dragging {
    box-shadow: $box-shadow;
  }

  &-handle:focus {
    outline: none;
  }

  &-handle-click-focused:focus {
    box-shadow: $box-shadow;
  }

  &-handle:hover {
    box-shadow: $box-shadow;
  }

  &-handle:active {
    box-shadow: $box-shadow;
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }

  &-mark {
    position: absolute;
    top: px(5);
    left: 0;

    width: 100%;

    font-size: $font-size-sm;
  }

  &-mark-text {
    position: absolute;

    display: inline-block;

    text-align: center;
    vertical-align: middle;

    cursor: pointer;

    @include transition(color);

    &:first-child {
      left: -10px !important;

      transform: none !important;
    }

    &:last-child {
      right: -10px !important;
      left: auto !important;

      transform: none !important;
    }

    &-active {
      color: color(primary);
    }
  }

  &-step {
    position: absolute;

    width: 100%;
    height: 2px;

    background-color: color(support, light);
  }

  &-dot {
    position: absolute;

    width: 20px;
    height: 20px;

    vertical-align: middle;

    background-color: color(support, light);
    border-radius: 50%;

    transform: translate(-50%, -50%);
    cursor: pointer;

    @include transition(background-color);

    &-active {
      background-color: color(primary);
    }
  }

  &-disabled {
    .rc-slider-handle,
    .rc-slider-disabled .rc-slider-dot {
      box-shadow: none;
      cursor: not-allowed;
    }

    .rc-slider-mark-text,
    .rc-slider-disabled .rc-slider-dot {
      cursor: not-allowed !important;
    }
  }
}
