.bold {
  font-weight: $font-weight-bold;
}

.regular {
  font-weight: $font-weight-normal;
}

.italic {
  font-style: italic;
}

.caps {
  text-transform: uppercase;
}

.lower {
  text-transform: lowercase;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.nowrap {
  white-space: nowrap;
}

.break-word {
  word-wrap: break-word;
}

.underline {
  text-decoration: underline;
}

.pointer {
  cursor: pointer;
}

.truncate {
  max-width: 100%;
  overflow: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;
}

.light {
  font-weight: $font-weight-light;
}
