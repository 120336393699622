.aspect {
  @include aspect(100%, "1:1");
}

.swiper-container {
  width: 100%;

  .swiper-slide {
    display: flex;
    flex-direction: column;
  }
}

.HTML {
  > *:last-child {
    margin-bottom: 0;
  }
}
