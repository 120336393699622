@import "@Core/assets/styles/abstracts";

.PreprRendererText {
  .Heading {
    color: color(primary);
  }

  p,
  ul {
    margin-bottom: px(4);
  }

  p + ul {
    margin-top: px(-2);
  }
}
