@import "@Core/assets/styles/abstracts";

.PreprRendererQuote {
  margin-top: px(3);
  margin-bottom: px(7);

  .Quote {
    margin: 0 !important;
    color: color(secondary);

    p {
      font-size: $font-size-h2;
      margin-bottom: px(1);
    }

    cite {
      color: $body-color;
    }
  }
}
