@import "@Core/assets/styles/abstracts";

.Container {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;

  &--Card {
    margin: 0 px(-2) px(2) px(-2);

    .Swiper {
      width: calc(100% - 32px);
      margin: 0 px(2);
      overflow: visible !important;
    }

    .Pagination {
      margin: 0 px(2);
    }
  }
}

.ButtonDisabled {
  button {
    cursor: not-allowed !important;
    opacity: 0.4;

    pointer-events: auto !important;
  }
}

.Pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: px(-1);
}
