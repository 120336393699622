@import "@Core/assets/styles/abstracts";

.Wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  footer {
    margin-top: auto;
  }
}

.Introduction {
  margin-top: px(6);

  .Navigation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    margin-top: px(4);
  }

  .Video {
    width: 100%;
    margin-bottom: px(3);

    @include aspect();

    iframe {
      width: 100%;
      height: 100%;
    }
  }

  .Image {
    margin-bottom: px(3);

    img {
      max-width: 100%;
      max-height: 200px;
    }
  }

  .Form {
    max-width: 360px;
    margin: 0 auto px(3) auto;
  }

  .Step {
    max-width: 570px;
    margin: 0 auto;

    > *:last-child {
      margin-bottom: 0;
    }
  }

  .Progress {
    position: absolute;
    top: px(2);
    transform: translate(-50%, -50%);
    left: 50%;
  }
}
