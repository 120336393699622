@import "@Core/assets/styles/abstracts";

.Banner {
  margin-bottom: px(6);

  .Time {
    display: flex;
    align-items: center;
    margin-right: px(3);

    color: color(primary);
    font-weight: $font-weight-normal;
    font-size: $font-size-sm;
    line-height: 32px;
    white-space: nowrap;

    i {
      margin-right: px(1);

      font-size: $font-size-xs;

      transform: translateY(-3px);
    }
  }

  .BannerFooter {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    margin-top: px(4);

    .Tags {
      a {
        margin-right: px(1);
        margin-bottom: px(0.5);
        padding-right: px(1.5);
        padding-left: px(1.5);
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .Banner {
    .BannerFooter {
      flex-direction: column;
      align-items: flex-start;

      .Time {
        display: none;
      }
    }
  }
}

.Podcast {
  padding-bottom: px(4);
}

.Content {
  padding-right: px(8);
  padding-bottom: px(2);
  padding-left: px(8);

  font-size: $font-size-md;
}

@include media-breakpoint-down(md) {
  .Content {
    padding-right: 0;
    padding-left: 0;
  }
}

.Activation {
  margin-right: px(-4);
  margin-left: px(-4);
  padding: px(8) px(4);

  background-color: color(secondary, 800);
}
