.Toastify {
  &__toast-container {
    @media only screen and (max-width: 480px) {
      padding: 15px !important;
    }
  }

  &__toast {
    margin-bottom: px(1) !important;

    border-radius: $border-radius !important;
  }
}

$rt-toast-width: 280px;
$rt-toast-background: #fff;
$rt-toast-min-height: 48px;
$rt-toast-max-height: 800px;

$rt-color-default: color(white);
$rt-color-dark: color(black);
$rt-color-info: color(tertiary);
$rt-color-success: color(succes);
$rt-color-warning: color(warning);
$rt-color-error: color(error);

$rt-text-color-default: #aaa;
$rt-text-color-dark: #fff;

$rt-color-progress-default: color(support);
$rt-color-progress-dark: color(support);
$rt-mobile: "only screen and (max-width : 480px)";
$rt-font-family: $font-family-base;
$rt-z-index: 9999;

@import "~react-toastify/scss/main.scss";
