@import "@Core/assets/styles/abstracts";

.Share {
  display: inline-flex;
  align-items: center;

  white-space: nowrap;

  vertical-align: middle;

  label {
    color: color(primary);
    font-size: $font-size-sm;
    padding-bottom: 0;
  }

  .Social {
    a {
      margin-left: px(2);
    }
  }

  .Download {
    margin-right: 0;
    margin-left: px(2);
  }

  .Button {
    margin-left: px(2);
  }
}

.isBlock {
  display: flex;
  justify-content: center;

  text-align: center;

  label {
    margin-right: px(3);

    color: color(secondary);
    font-weight: $font-weight-bold;
    font-size: $font-size-h3;
  }
}

.hasBackground {
  background-color: color(secondary, 700);
}

@include media-breakpoint-down(lg) {
  .isBlock {
    flex-direction: column;

    label {
      margin-right: 0;
    }

    .Button {
      margin-top: px(1);

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .Download {
    margin-top: px(2);
  }
}

@include media-breakpoint-down(sm) {
  .isBlock {
    padding-top: px(3);
    padding-bottom: px(3);
  }
}
