@import "@Core/assets/styles/abstracts";

.Field {
  position: relative;
}

.Icon {
  position: absolute;
  top: 50%;
  right: 0;

  color: color(primary);

  transform: translateY(-50%);
}
