@import "@Core/assets/styles/abstracts";

.Image {
  display: block;
  width: 100%;
  max-width: 300px;

  @include media-breakpoint-down(md) {
    margin-right: auto;
    margin-bottom: px(4);
    margin-left: auto;
  }
}

.Buttons {
  display: flex;
  justify-content: space-between;
  margin-top: px(8);

  button:first-child {
    flex-direction: row-reverse;

    &::after {
      margin-right: px(1);
      margin-left: 0;

      transform: rotate(180deg) translateY(2px);
    }
  }

  button:last-child {
    i {
      margin-left: px(1);

      transform: translateY(-2px);
    }
  }
}
