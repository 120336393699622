@import "@Core/assets/styles/abstracts";

.Sidebar {
  position: absolute;
  z-index: 10;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  background-color: color(white);
  border: 2px solid color(white);
  border-radius: $border-radius;
  box-shadow: $box-shadow;

  @include media-breakpoint-up(lg) {
    &:hover {
      width: auto !important;

      .ItemTitle {
        display: block;
      }
    }
  }

  @include media-breakpoint-down(md) {
    position: fixed;

    top: auto;
    right: 0;
    bottom: 0;
    left: 0;

    flex-direction: row;
    justify-content: space-around;

    width: 100% !important;
  }

  @include media-breakpoint-up(lg) {
    padding-top: px(1);
    padding-bottom: px(1);

    &.SidebarSticky {
      position: fixed;
      top: 15px;
    }
  }
}

.Item {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: px(0.5);
  margin-bottom: px(0.5);

  background-color: transparent;

  cursor: pointer;

  @include transition(background-color);

  @media (hover: hover) {
    &:hover {
      background-color: color(tertiary, lighter);

      .ItemTitle,
      .ItemIcon {
        color: color(white);
      }
    }
  }

  .ItemTitle {
    display: none;
    margin-right: px(2);
    margin-left: px(2);

    white-space: nowrap;

    @include transition(color);
  }

  .ItemIcon {
    position: relative;

    min-width: 44px;
    height: 44px;

    color: color(tertiary);
    font-size: 20px;
    line-height: 44px;
    text-align: center;

    border-radius: 50%;

    @include transition(color);

    .ItemIconNotification {
      position: absolute;
      top: px(-0.5);
      left: 20px;

      width: 24px;
      height: 24px;

      color: color(white);
      font-size: $font-size-sm;

      line-height: 27px;
      text-align: center;

      background-color: color(error, light);
      border-radius: 50%;

      font-family: $font-family-base;
    }
  }

  &.ItemSelected {
    //background-color: color(white);
    background-color: color(tertiary);

    .ItemTitle,
    .ItemIcon {
      color: color(white);
    }
  }

  @include media-breakpoint-only(xl) {
    margin-left: px(1);
    margin-right: px(1);

    border-radius: 44px;

    .ItemIcon {
      .ItemIconNotification {
        left: 24px;
      }
    }
  }

  @include media-breakpoint-only(lg) {
    margin-left: 5px;
  }

  @include media-breakpoint-down(lg) {
    border-radius: 36px;

    .ItemIcon {
      min-width: 36px;
      height: 36px;

      font-size: 18px;
      line-height: 36px;

      .ItemIconNotification {
        width: 20px;
        height: 20px;

        font-size: $font-size-sm;

        line-height: 20px;
      }
    }
  }
  @include media-breakpoint-down(md) {
    .ItemTitle {
      display: none;
    }
  }
}
