@import "@Core/assets/styles/abstracts";

.Button {
  position: relative;

  display: inline-flex;
  align-items: center;
  width: auto;
  max-width: 100%;

  padding: $button-padding;
  overflow: visible;

  color: inherit;

  font-size: $button-font-size;
  font-family: $font-family-base;
  line-height: 1.4;
  white-space: nowrap;

  text-decoration: none;
  vertical-align: middle;

  background: none;
  border-color: transparent;
  border-style: solid;
  border-width: 1px;

  border-radius: $button-border-radius;

  appearance: none;
  user-select: none;

  @include transition(background-color);

  &:focus {
    outline: none;
  }

  &:focus,
  &:hover {
    cursor: pointer;
  }

  &:active {
    opacity: 1;
  }

  &:disabled {
    opacity: 0.5;

    &:hover {
      cursor: not-allowed;
    }
  }

  &.Small {
    padding: 0;

    font-size: $font-size-sm;
  }
}

.Underline {
	text-decoration: underline;

	&:hover {
		text-decoration: none;
	}
}

.Icon {
	width: 32px;
	height: 32px;
	border-radius: 50%;
	justify-content: center;
	padding: 0;

  i {
    font-size: $font-size-sm;
  }
}

.IconLeft {
	i {
		margin-right: px(1.5);

		transform: translateY(-1px);
	}
}

.IconRight {
	i {
		margin-left: px(1.5);

		transform: translateY(-1px);
	}
}

.Large {
  padding: 13px px(2) 11px px(2);

  font-weight: $font-weight-medium;
  font-size: $font-size-base;
}

.Block {
  display: block;
  width: 100%;

  text-align: center;
}

.Primary {
  color: color(primary);
  font-weight: $font-weight-medium;

  background-color: color(primary, 400);

  svg {
    color: color(primary);
  }

  &:hover {
    color: color(primary);

    background-color: color(primary, 300);
  }
}

.Secondary {
  color: color(secondary);
  font-weight: $font-weight-medium;

  background-color: color(secondary, 300);

  &:hover {
    color: color(secondary);

    background-color: color(secondary, 600);
  }
}

.TertiaryLNI {
  color: color(white);
  font-weight: $font-weight-medium;

  background-color: color(tertiary);

  &:hover {
    color: color(white);

    background-color: color(tertiary, darker);
  }
}

.TertiaryAOM {
  color: color(white);
  font-weight: $font-weight-medium;

  background-color: color(primary);

  &:hover {
    color: color(white);

    background-color: color(primary, darker);
  }
}

.Quaternary {
  color: color(white);
  font-weight: $font-weight-medium;

  background-color: color(quaternary);

  &:hover {
    color: color(white);

    background-color: color(quaternary, darker);
  }
}

.Danger {
	color: color(white);
	font-weight: $font-weight-medium;

	background-color: color(error, light);

	&:hover {
		color: color(white);

		background-color: color(error, dark);
	}
}

.LinkPrimary {
  color: color(primary);
  font-weight: $font-weight-medium;

  background-color: transparent;
}

.LinkSecondary {
  color: color(secondary);
  font-weight: $font-weight-medium;

  background-color: transparent;
}

.LinkTertiaryAOM {
  color: color(primary);
  font-weight: $font-weight-medium;

  background-color: transparent;
}

.LinkTertiaryLNI {
  color: color(tertiary);
  font-weight: $font-weight-medium;

  background-color: transparent;
}

.LinkQuaternary {
  color: color(quaternary);
  font-weight: $font-weight-medium;

  background-color: transparent;
}

.LinkDanger {
	color: color(error, light);
	font-weight: $font-weight-medium;

	background-color: transparent;
}

.LinkPrimaryHover,
.LinkSecondaryHover,
.LinkTertiaryHover,
.LinkQuaternaryHover,
.LinkDangerHover {
	transition-property: background-color;

  @include transition(all);
}

.LinkPrimaryHover {
	&:hover {
		background-color: color(primary, 500);
	}
}

.LinkSecondaryHover {
	&:hover {
		background-color: color(secondary, 700);
	}
}

.LinkTertiaryHover {
	&:hover {
		background-color: color(tertiary, lighten);
	}
}

.LinkQuaternaryHover {
	&:hover {
		background-color: color(quaternary, light);
	}
}

.LinkDangerHover {
	&:hover {
		background-color: color(error, lighter);
	}
}
