@import "@Core/assets/styles/abstracts";

.Item {
  margin-bottom: px(3);

  &:last-child {
    margin-bottom: 0;
  }
}

.Label {
  @include transition(color);
}

.ItemError {
  .Label,
  .Error {
    color: color(error);

    text-align: left;
    transform: translateY(0);
    visibility: visible;
    opacity: 1;
  }

  .Error {
    margin-top: px(1);
    padding-left: px(2);

    font-weight: $font-weight-light;

    font-size: $font-size-sm;
  }
}

.Field {
}

.Description {
  margin-top: px(1);

  color: color(support);
  font-weight: $font-weight-light;
  font-size: $font-size-sm;
}
