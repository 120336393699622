*,
::after,
::before {
  box-sizing: border-box;
}

::after,
::before {
  text-decoration: inherit;
  vertical-align: inherit;
}

::selection {
  background: color(secondary, 500);
}

html {
  line-height: 1.5;
  word-break: break-word;
  -moz-tab-size: 4;
  tab-size: 4;

  cursor: default;
  -webkit-tap-highlight-color: transparent;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: color(support, lightest);
}

.root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
}

h1 {
  margin: 0.67em 0;

  font-size: 2em;
}

dl dl,
dl ol,
dl ul,
ol dl,
ul dl {
  margin: 0;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}

hr {
  height: 0;
  overflow: visible;
}

main {
  display: block;
}

nav ol,
nav ul {
  padding: 0;

  list-style: none;
}

pre {
  font-size: 1em;
  font-family: monospace, monospace;
}

a {
  background-color: transparent;
}

abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-size: 1em;
  font-family: monospace, monospace;
}

small {
  font-size: 80%;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

audio,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

svg:not([fill]) {
  fill: currentColor;
}

svg:not(:root) {
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

button,
input,
select {
  margin: 0;
}

button {
  overflow: visible;

  text-transform: none;
}

[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: button;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

input {
  overflow: visible;
}

legend {
  display: table;
  max-width: 100%;

  color: inherit;
  white-space: normal;
}

progress {
  display: inline-block;

  vertical-align: baseline;
}

select {
  text-transform: none;
}

textarea {
  margin: 0;
  overflow: auto;

  resize: vertical;
}

[type="checkbox"],
[type="radio"] {
  padding: 0;
}

[type="search"] {
  outline-offset: -2px;

  -webkit-appearance: textfield;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

::-webkit-input-placeholder {
  color: inherit;

  opacity: 0.54;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;

  -webkit-appearance: button;
}

::-moz-focus-inner {
  padding: 0;
}

:-moz-focusring {
  outline: 1px dotted ButtonText;
}

:-moz-ui-invalid {
  box-shadow: none;
}

details {
  display: block;
}

dialog {
  position: absolute;
  right: 0;
  left: 0;

  display: block;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  margin: auto;
  padding: 1em;

  color: color(black);

  background-color: color(white);
  border: solid;
}

dialog:not([open]) {
  display: none;
}

summary {
  display: list-item;
}

canvas {
  display: inline-block;
}

template {
  display: none;
}

[tabindex],
a,
area,
button,
input,
label,
select,
summary,
textarea {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

[hidden] {
  display: none;
}

[aria-busy="true"] {
  cursor: progress;
}

[aria-controls] {
  cursor: pointer;
}

[aria-disabled="true"],
[disabled] {
  cursor: not-allowed;
}

[aria-hidden="false"][hidden] {
  display: initial;
}

[aria-hidden="false"][hidden]:not(:focus) {
  position: absolute;

  clip: rect(0, 0, 0, 0);
}

#toggledebug {
  display: none !important;
}
