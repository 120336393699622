@import "@Core/assets/styles/abstracts";

.Wrapper {
  position: relative;

  display: block;
  align-items: flex-start;
  height: 100%;
  padding: px(2);
  overflow: hidden;

  background-color: color(white);
  border: 2px solid color(support, light);
  border-radius: $border-radius;

  transform: translateY(0);

  transition-property: box-shadow border transform;

  @include transition(all);

  &:hover {
    border: 2px solid color(support, lightest);
    box-shadow: $box-shadow;
    transform: translateY(-2px);
  }
}

.Disabled {
  opacity: 0.5;
  filter: blur(1px);

  user-select: none;
  pointer-events: none;
}
