@import "@Core/assets/styles/abstracts";

.ArticlesSearch {
	position: relative;
	max-width: 740px;
	margin: px(2) auto;
	display: flex;
	align-items: center;

	.Input {
		background-color: color(primary, 400);
		border-color: color(primary, 400);
	}

	.Button {
		margin-left: px(2);
	}
}