@import "@Core/assets/styles/abstracts";

.Blockquote {
  p {
    color: color(secondary);
    font-weight: $font-weight-regular;
    font-size: $font-size-h2;
  }

  cite {
    display: block;
    margin-top: px(1);

    font-size: $font-size-md;

    &::before {
      margin-right: px(0.5);

      content: "-";
    }
  }
}
