@import "@Core/assets/styles/abstracts";

.Wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.Profile {
  display: flex;
  align-items: center;
  margin-bottom: px(3);

  .Image {
    position: relative;

    padding-right: px(1.5);
    padding-bottom: px(1.5);

    .Icon {
      position: relative;
      z-index: 1;

      display: flex;
      align-items: center;
      justify-content: center;
      width: 64px;
      min-width: 64px;
      height: 64px;

      margin-right: px(2);
      object-fit: cover;
      object-position: center;

      background-color: color(primary, 400);

      border-radius: 100%;

      i {
        color: color(primary);
        font-size: 20px;
      }
    }

    .Circle {
      position: absolute;
      top: px(1.5) !important;
      left: px(1.5) !important;

      transform: none;
    }
  }

  .Name {
    font-weight: bold;
    font-size: $font-size-md;
  }
}

.Buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: auto;
}

.ProfileLarge {
  flex-direction: column;

  text-align: center;

  .Image {
    position: relative;

    .Icon {
      width: 130px;
      min-width: 130px;
      height: 130px;
      margin-right: 0;
    }

    .Circle {
      top: -20px !important;
      left: -40px !important;
    }
  }
}
