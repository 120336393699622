@import "@Core/assets/styles/abstracts";

.StepRow {
	display: grid;
	grid-template-columns: auto 1fr;
	border-bottom: 1px solid color(support, light);
	padding: px(1.5) 0;
	column-gap: px(3);
	row-gap: px(1);
	align-items: center;

	&:first-child {
		padding-top: 0;
	}

	&:last-child {
		padding-bottom: 0;
		border-bottom: none;
	}

	.Checkbox {
		grid-area: 1 / 1 / 2 / 2;
	}

	.Wrapper {
		grid-area: 1 / 0 / 2 / 3;
		display: flex;
		align-items: center;
		gap: px(3);

		.Title {
			font-weight: $font-weight-medium;

			&--button {
				cursor: pointer;
				text-decoration: underline;

				&:hover {
					text-decoration: none;
				}
			}
		}
	}

	@include media-breakpoint-up(md) {
		row-gap: 0;
		grid-template-rows: 48px;
		grid-template-columns: auto 1fr auto 220px;
	}
}
