@import "@Core/assets/styles/abstracts";

.Overlay {
	position: fixed;
	inset: 0;
	z-index: +1000;
	background: color(black);
	animation: fadeIn 200ms both;
}

.Modal {
	position: fixed;
	top: 50%;
	left: 50%;
	z-index: +1000;
	display: flex;
	flex-direction: column;
	width: 500px;
	max-width: calc(100% - 30px);
	max-height: calc(100% - 30px);
	padding: 30px;
	background-color: color(white);
	border-radius: $border-radius;
	overflow-y: auto;
	box-shadow: $box-shadow;
	animation: modalFadeIn 200ms both;

	&.ModalLarge {
		width: 740px;
	}

  .Header {
    display: flex;
    align-items: center;
    margin-bottom: px(2);

    .Title {
      margin-bottom: 0;
    }

		.Close {
			margin-left: auto;
			cursor: pointer;
		}
	}

  .Body {
    display: flex;
    flex-direction: row;

    @include media-breakpoint-down(md) {
      flex-direction: column;
    }

    .Content {
      width: 100%;

      > *:last-child {
        margin-bottom: 0;
      }

      label {
        color: color(black);
      }
    }

    .Image {
      img {
        width: 260px;
        max-width: 260px;
        margin-left: px(2);

        @include media-breakpoint-down(md) {
          display: block;
          margin: px(2) auto 0 auto;
        }
      }
    }
  }
}
