@import "@Core/assets/styles/abstracts";

.Fieldset {
  min-width: 0;
  margin: 0 0 px(5) 0;
  padding: 0;

  border: 0;
}

.Title {
  margin: 0 0 px(1) 0;
  padding: 0;

  font-weight: $font-weight-bold;
  font-size: $font-size-h4;
}

.Inputs {
}
