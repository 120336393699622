@font-face {
  font-weight: 900;
  font-family: "Azo Sans";
  font-style: normal;
  font-display: swap;
  src: url("../../fonts/AzoSans-Black.woff2") format("woff2"), url("../../fonts/AzoSans-Black.woff") format("woff");
}

@font-face {
  font-weight: bold;
  font-family: "Azo Sans";
  font-style: italic;
  font-display: swap;
  src: url("../../fonts/AzoSans-BoldItalic.woff2") format("woff2"),
    url("../../fonts/AzoSans-BoldItalic.woff") format("woff");
}

@font-face {
  font-weight: normal;
  font-family: "Azo Sans";
  font-style: italic;
  font-display: swap;
  src: url("../../fonts/AzoSans-Italic.woff2") format("woff2"), url("../../fonts/AzoSans-Italic.woff") format("woff");
}

@font-face {
  font-weight: bold;
  font-family: "Azo Sans";
  font-style: normal;
  font-display: swap;
  src: url("../../fonts/AzoSans-Bold.woff2") format("woff2"), url("../../fonts/AzoSans-Bold.woff") format("woff");
}

@font-face {
  font-weight: 100;
  font-family: "Azo Sans";
  font-style: italic;
  font-display: swap;
  src: url("../../fonts/AzoSans-ThinItalic.woff2") format("woff2"),
    url("../../fonts/AzoSans-ThinItalic.woff") format("woff");
}

@font-face {
  font-weight: 900;
  font-family: "Azo Sans";
  font-style: italic;
  font-display: swap;
  src: url("../../fonts/AzoSans-BlackItalic.woff2") format("woff2"),
    url("../../fonts/AzoSans-BlackItalic.woff") format("woff");
}

@font-face {
  font-weight: 300;
  font-family: "Azo Sans";
  font-style: normal;
  font-display: swap;
  src: url("../../fonts/AzoSans-Light.woff2") format("woff2"), url("../../fonts/AzoSans-Light.woff") format("woff");
}

@font-face {
  font-weight: 500;
  font-family: "Azo Sans";
  font-style: italic;
  font-display: swap;
  src: url("../../fonts/AzoSans-MediumItalic.woff2") format("woff2"),
    url("../../fonts/AzoSans-MediumItalic.woff") format("woff");
}

@font-face {
  font-weight: 500;
  font-family: "Azo Sans";
  font-style: normal;
  font-display: swap;
  src: url("../../fonts/AzoSans-Medium.woff2") format("woff2"), url("../../fonts/AzoSans-Medium.woff") format("woff");
}

@font-face {
  font-weight: 300;
  font-family: "Azo Sans";
  font-style: italic;
  font-display: swap;
  src: url("../../fonts/AzoSans-LightItalic.woff2") format("woff2"),
    url("../../fonts/AzoSans-LightItalic.woff") format("woff");
}

@font-face {
  font-weight: normal;
  font-family: "Azo Sans";
  font-style: normal;
  font-display: swap;
  src: url("../../fonts/AzoSans-Regular.woff2") format("woff2"), url("../../fonts/AzoSans-Regular.woff") format("woff");
}

@font-face {
  font-weight: 100;
  font-family: "Azo Sans";
  font-style: normal;
  font-display: swap;
  src: url("../../fonts/AzoSans-Thin.woff2") format("woff2"), url("../../fonts/AzoSans-Thin.woff") format("woff");
}
