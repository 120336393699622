@import "@Core/assets/styles/abstracts";

.RichText {
	hr {
		margin-top: px(1);
		margin-bottom: px(3);
	}

	.Content {
		cursor: text;

		> div {
			outline-color: transparent;
		}

		p {
			color: color(black);
			font-weight: $font-weight-regular;
			font-size: $input-font-size;
		}

		[data-placeholder]::before {
			content: attr(data-placeholder);
			float: left;
			color: color(support);
			pointer-events: none;
			height: 0;
		}

		*:last-child {
			margin-bottom: 0;
		}

		&.isDisabled {
			cursor: not-allowed;
			margin-top: px(2);
		}
	}

	.Fallback {
		cursor: not-allowed;
		margin: px(2.5) 0 0;
		color: color(support);
		font-weight: $font-weight-regular;
		font-size: $input-font-size;
	}
}
