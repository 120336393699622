@import "@Core/assets/styles/abstracts";

.Form {
  p {
    color: color(support);
  }
}

.Error {
  margin-bottom: px(2);
  padding: px(1) px(2);

  color: color(white);

  background-color: color(error);
  border: 1px solid darken(color(error), 20%);
  border-radius: $border-radius;
}

.Fieldset {
	min-width: 0;
	padding: 0;

	border: 0;
	margin: 0 0 px(3) 0;

  &:last-child {
    margin-bottom: 0;
  }
}

.Footer {
  .Submit {
    transition-property: opacity background-color;

    @include transition(all);

    &.SubmitLoading {
      .Loading {
        opacity: 1;
      }
    }

    &.SubmitSuccess {
      background-color: color(succes);

      .Success {
        opacity: 1;
      }
    }

    &.SubmitFailure {
      background-color: color(error);

      .Failure {
        opacity: 1;
      }
    }

    &.SubmitDefault {
      .Text {
        opacity: 1;
      }
    }

    .Loading,
    .Icon,
    .Text {
      opacity: 0;

      @include transition(opacity);
    }

    .Loading,
    .Icon {
      position: absolute;
      top: 50%;
      left: 50%;

      transform: translate(-50%, -50%);
    }
  }
}
