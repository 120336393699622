@import "@Core/assets/styles/abstracts";

.RichTextMenuBar {
	display: flex;
	flex-wrap: wrap;
	gap: px(0.5);

	.Button {
		padding: 0;

		&.isActive {
			background-color: color(support, light);
		}

		&:not(:disabled):hover {
			background-color: color(support, light);
		}
	}

	.Settings {
		margin-left: auto;
		display: flex;
		gap: px(0.5);
	}
}

.ButtonGroup {
	margin-top: px(4);
	display: flex;
	gap: px(2);
}
