@import "@Core/assets/styles/abstracts";

.InfoModal {
	cursor: pointer;
	@include transition(color);

	&--variant-primary {
		color: color(primary);
	}

	&--variant-danger {
		color: color(error, light);
	}
}
