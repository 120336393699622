@import "@Core/assets/styles/abstracts";

.FileQuestions {
	.Question {
		&:not(:last-child) {
			border-bottom: 1px solid color(support, light);
			padding-bottom: px(3);
			margin-bottom: px(3);
		}

		.Answers {
			.Answer {
				padding: px(1) px(2);
				border-radius: 4px;
				background-color: color(primary, 400);

				&:not(:last-child) {
					margin-bottom: px(1);
				}

				.Icon {
					color: color(primary, 100);
					margin-right: px(1.5);
				}
			}
		}
	}
}