@import "@Core/assets/styles/abstracts";

.StackListItem {
	.Title {
		font-size: $font-size-xs;
		font-weight: $font-weight-bold;
		text-transform: uppercase;
	}


	.Wrapper {
		display: flex;
		align-items: center;
		margin-top: px(0.5);
	}

	&.hasIcon {
		.Icon {
			font-size: $font-size-xs;
			color: color(support);
			margin-right: px(1);
			transform: translateY(-1px);
		}
	}

	&.hasOnClick {
		.Wrapper {
			text-decoration: underline;
			cursor: pointer;
		}
	}
}
