// 1. Settings
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$theme-colors: (
	primary: (
		default: #2b4982,
		darker: darken(#2b4982, 10%),
		100: #2856ab,
		200: #5a89e3,
		300: #d7e2f3,
		400: #e9f0fd,
		500: #f2f8ff,
		600: #f7f7fd,
	),
	secondary: (
		default: #56bbad,
		100: #95bdc0,
		200: #d2e2e3,
		300: #ddf0f0,
		400: #def0ed,
		500: #e8f1f1,
		600: #ecf4f4,
		700: #f3faf9,
		800: #f9fdfc,
	),
	tertiary: (
		lighter: lighten(#69548d, 15%),
		light: lighten(#69548d, 10%),
		default: #69548d,
		darker: darken(#69548d, 10%),
		darkest: darken(#69548d, 15%),
	),
	quaternary: (
		lighter: #fffaf3,
		light: #ffe4b3,
		default: #ffb228,
	),
	black: (
		default: #2e384d,
		light: #77899a,
	),
	white: (
		default: #ffffff,
		100: #d7e2f3,
		200: #e9f0fd,
		300: #f2f8ff,
		400: #f7f7fd,
		500: #fafcff,
	),
	support: (
		default: #8d9eb8,
		lightest: #fafbff,
		light: #e0e7ff,
		dark: #3c4962,
		darkest: #67778f,
	),
	succes: (
		default: #56bbad,
		dark: #28b440,
	),
	warning: (
		default: #ffcc00,
		dark: #e4b80b,
	),
	error: (
		default: #ff6b57,
		dark: #d12d17,
		light: #ff0000,
		lighter: #fee2de,
	),
);

$gradients: (
  primary: linear-gradient(to bottom, color(primary), color(white)),
  secondary: linear-gradient(to bottom, color(secondary), color(white)),
  tertiary: linear-gradient(to bottom, color(white, 100), color(white)),
  dark: linear-gradient(to bottom, color(black), color(white)),
  light: linear-gradient(to bottom, color(white), color(white)),
);

$shadows: (
  md: (
    0 16px 24px 0 rgba(212, 212, 212, 0.5),
  ),
);

$transitions: (
  xs: 0.1s ease-in-out,
  sm: 0.15s ease-in-out,
  md: 0.2s ease-in-out,
  lg: 0.3s ease-in-out,
  xl: 0.5s ease-in-out,
);

$spacings: (
	none: 0,
	xs: px(1),
	sm: px(2),
	smd: px(3),
	md: px(4),
	lg: px(6),
	xl: px(10),
);

$box-shadow: 0 4px 6px 1px rgba(13, 38, 53, 0.06), 0 0 1px 1px rgba(13, 38, 53, 0.02);

// 2. Options
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$body-color: #2e384d;
$body-background: #ffffff;

$border-radius: px(1);
$default-margin: px(3);

// 2.1 Typography
$font-family-sans-serif: "Azo Sans", sans-serif;
$font-family-monospace: "Courier New", Courier, monospace;
$font-family-base: $font-family-sans-serif;
$font-family-icons: "Font Awesome 5 Pro";

$font-weight-thin: 100;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

$font-size-base: 16px;
$font-weight-base: $font-weight-light;
$line-height-base: 1.5;
$font-family-base: $font-family-base;

$font-size-xxs: 10px;
$font-size-xs: 12px;
$font-size-sm: 14px;
$font-size-md: 16px;
$font-size-lg: 28px;
$font-size-xl: 56px;

$headings-font-family: $font-family-sans-serif;
$headings-color: color(black);
$headings-font-weight: 500;
$headings-line-height: 1.3;
$headings-margin-bottom: px(1);

$font-size-h1: 34px;
$font-size-h2: 24px;
$font-size-h3: 20px;
$font-size-h4: 18px;
$font-size-h5: 16px;
$font-size-h6: 14px;

$paragraph-margin: $default-margin;
$paragraph-lead-font-size: $font-size-md;
$paragraph-lead-margin-bottom: $paragraph-margin * 1.2;

$link-color: color(secondary);
$link-decoration: none;
$link-hover-color: darken($link-color, 20%);
$link-hover-decoration: none;

$hr-color: color(support, light);
$hr-height: spacing(lg);

$list-margin: $default-margin;
$list-item-margin: px(0.5);

$blockquote-margin: px(3) 0 px(7) 0;
$blockquote-padding: px(4) px(3);

// 2.2 Buttons
$button-colors: (
  primary: color(primary),
  secondary: color(secondary),
);

$button-font-weight: $font-weight-regular;
$button-font-color: white;
$button-border-radius: $border-radius;

$button-sm-padding: px(0.5) px(1.5);
$button-padding: 7px 15px 4px 15px;
$button-lg-padding: px(1.5) px(3);

$button-sm-font-size: $font-size-sm;
$button-font-size: $font-size-sm;
$button-lg-font-size: $font-size-lg;

// 2.3 Forms
$input-font-size: $font-size-md;
$input-label-font-size: $font-size-base;
$input-background-color: color(support, lightest);
$input-active-color: color(primary, 300);
$input-radius: $border-radius;
$input-border-color: color(support, light);
$input-invalid-color: color(error);
$input-placeholder-color: color(primary);
$input-radio-checkbox: color(primary);

// 2.4 Images
$image-margin-bottom: $default-margin;

// 3. Layouts
//
// Define some settings for custom components, such as the header
// FAQ, and other components.

// 3.1 Header
$header-toggler-height: 18px;
$header-toggler-width: 23px;
$header-toggler-color: color(primary);

$header-mobile-breakpoint: md;
$header-mobile-background: color(support, light);
