@import "@Core/assets/styles/abstracts";

.Menu {
  overflow: auto;

  > div {
    padding: px(2) px(4);
    white-space: nowrap;
  }

  .Item {
    color: $body-color;
    font-weight: normal;
    font-size: $font-size-sm;
    margin-right: px(0.5);

    .Icon {
      position: relative;
      margin-right: px(2);
      color: color(primary);
      transform: translateY(-2px);

      .Count {
        position: absolute;
        font-family: $font-family-sans-serif;
        top: 0;
        font-size: $font-size-xs;
        right: 0;
        top: 0;
        transform: translate(50%, -100%);
        width: 20px;
        line-height: 24px;
        text-align: center;
        height: 20px;
        border-radius: 50%;
        color: color(white);
        background-color: color(error, light);
      }
    }

    &:hover,
    &:first-child {
      background-color: color(primary);
      color: color(white);

      .Icon {
        color: color(white);
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
