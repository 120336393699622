@import "@Core/assets/styles/abstracts";

.Field {
	position: relative;
	flex-grow: 1;
}

.Icon {
  position: absolute;
  top: 50%;
  right: px(2);

  color: color(primary);

  transform: translateY(-50%);

  opacity: 0.4;

  user-select: none;
  pointer-events: none;

  @include transition(opacity);
}

.Input:focus + .Icon {
  opacity: 1;
}
