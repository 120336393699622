@import "@Core/assets/styles/abstracts";

.GuidelinesOverview {
	.List {
		.Item {
			font-size: $font-size-md;
		}

		&:last-of-type {
			margin-bottom: 0;
		}
	}
}
