@import "@Core/assets/styles/abstracts";

.Wrapper {
  position: relative;
  z-index: 999;

  padding-top: px(3);
  padding-bottom: px(3);

  background-color: color(white);

  border-bottom: 1px solid color(support, light);
}

.Inner {
  display: flex;
  align-items: center;
}

.Profile {
  position: relative;

  margin-left: auto;

  user-select: none;
}

.ProfileWrapper {
  display: flex;
  align-items: center;

  cursor: pointer;

  .ProfileName {
    margin-right: px(1);

    font-size: $font-size-md;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .ProfileToggle {
    margin-left: px(1);

    font-size: $font-size-xs;

    @include transition(transform);
  }
}

.ProfileDropdown {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 999;

  margin-top: px(1);

  padding: px(1.5) px(2);

  background-color: color(white);
  border-radius: $border-radius;
  box-shadow: 0 6px 10px 2px rgba(0, 0, 0, 0.08), 0 0 2px 1px rgba(0, 0, 0, 0.03);
  transform: translateY(px(-2));
  opacity: 0;

  transition-property: opacity transform;

  user-select: none;
  pointer-events: none;

  @include transition(all);

  a {
    display: flex;
    align-items: center;
    padding: px(0.5) 0;

    color: color(support);
    white-space: nowrap;

    cursor: pointer;

    i {
      margin-right: px(1);
      font-size: $font-size-xs;
    }

    &:hover {
      color: color(tertiary);
    }
  }
}

.ProfileActive {
  .ProfileToggle {
    transform: rotate(180deg);
  }

  .ProfileDropdown {
    transform: translateY(0);
    opacity: 1;

    user-select: auto;
    pointer-events: all;
  }
}
