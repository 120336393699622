@import "@Core/assets/styles/abstracts";

.Wrapper {
  display: flex;
  height: 100%;
  padding-bottom: px(4);

  .Block {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: 0;
  }

  .Body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.Block {
  width: 100%;
  margin-bottom: px(4);

  position: relative;

  overflow: hidden;

  background-color: color(white);
  border: 2px solid color(white);
  border-radius: $border-radius;

  box-shadow: $box-shadow;

  @include transition(border-color);

  &.Hidden {
    .Title {
      margin-bottom: px(4);
    }

    .Body {
      display: none;
    }

    .Toggle {
      transform: rotate(180deg);
    }
  }
}

.Toggle {
  margin-left: auto;
  margin-right: px(4);
  display: flex;
  justify-content: center;
  align-items: center;
  color: color(white);
  width: 32px;
  cursor: pointer;
  height: 32px;
  background-color: color(primary);
  border-radius: 100%;
}

.Title {
  display: flex;
  align-items: center;

  margin-bottom: px(-1);
  padding: px(4) 0 0 px(4);

  color: color(support);

  font-size: $font-size-sm;
  text-transform: uppercase;
}

.Body {
  width: 100%;
  height: 100%;
  padding: px(4);

  > *:last-child {
    margin-bottom: 0;
  }
}

.Success {
  border: 2px solid color(succes);

  .Title {
    display: flex;

    &::after {
      display: block;

      margin-left: px(1);

      color: color(succes);

      font-family: $font-family-icons;

      transform: translateY(-2px);

      content: "\f00c";
    }
  }
}

.Warning {
  border: 2px solid color(warning);

  .Title {
    display: flex;

    &::after {
      display: block;

      margin-left: px(1);

      color: color(warning);

      font-family: $font-family-icons;

      transform: translateY(-2px);

      content: "\f071";
    }
  }
}

.Primary {
  background-color: color(primary, 400);
  border-color: color(primary, 400);
}

.PrimaryLight {
  background-color: color(primary, 500);
  border-color: color(primary, 500);
}

.Small {
  .Body {
    padding: px(3);
  }
}

.None {
	.Body {
		padding: 0;
	}
}

.Center {
  .Title {
    display: block;
    padding-right: px(4);

    text-align: center;
  }

  .Body {
    text-align: center;
  }
}

.Info {
  padding-bottom: px(1);
  padding-left: px(1);

  color: color(tertiary);

  transform: translateY(px(-1));
  cursor: pointer;
}

.Visible {
  overflow: visible;
}

.Card {
  display: block;
  align-items: flex-start;
  margin-bottom: 0;
  overflow: hidden;

  background-color: color(white);
  border: 2px solid color(support, light);
  border-radius: $border-radius;
  box-shadow: none;
}

.Scroll {
  height: calc(100vh - 100px);
  overflow-y: scroll;

  box-shadow: inset 0px 0px 50px 10px rgba(255, 255, 255, 1);

	.Body {
		height: auto;
		padding-bottom: px(4);
	}
}