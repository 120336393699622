@import "@Core/assets/styles/abstracts";

.Navigation {
	display: flex;
	flex-wrap: wrap;
	border-bottom: 1px solid color(support, light);
}

.NavigationItem {
	position: relative;
	padding: px(3) px(4);
	color: color(support);
	font-weight: $font-weight-medium;

	&.isActive,
	&:hover {
		color: color(primary);

		&::after {
			content: "";
			position: absolute;
			bottom: -1px;
			left: 0;
			right: 0;
			height: 3px;
			background-color: color(primary, 100);
		}
	}
}