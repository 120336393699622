@import "@Core/assets/styles/abstracts";

.PreprRendererAsset {
  margin-bottom: px(4);
  border-radius: px(1);

  audio {
    width: 100%;
    margin-bottom: 0;
  }

  img {
    margin-bottom: 0;
  }
}
