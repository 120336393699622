@import "@Core/assets/styles/abstracts";

.Text {
  font-weight: $font-weight-light;

  p,
  ul,
  ol {
    margin-bottom: px(4);
  }

  p + ul {
    margin-top: px(-2);
  }
}

.File {
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: px(2);

  border: 1px solid color(secondary, 300);
  border-radius: $border-radius;
  cursor: pointer;

  user-select: none;

  @include transition(box-shadow);

  div {
    margin-right: px(2);
  }

  a {
    min-width: 32px;
    min-height: 32px;
    margin-right: px(1.5);

    font-size: $font-size-sm;
  }

  p {
    margin-bottom: 0;

    color: color(secondary);
    font-size: $font-size-sm;
  }

  &:hover {
    box-shadow: shadow();
  }

  &::after {
    position: absolute;
    top: 50%;
    right: 100%;
    z-index: 10;

    display: block;
    width: 28px;

    height: 1px;

    background-color: color(secondary, 300);
    transform: translateY(-50%);

    content: "";
  }
}

@include media-breakpoint-down(md) {
  .File {
    margin-bottom: px(4);

    &::after {
      top: auto;

      right: 50%;
      bottom: 100%;

      width: 1px;
      height: 20px;

      transform: translateX(-50%);
    }
  }
}
