@import "@Core/assets/styles/abstracts";

.Inputs {
  .Wrapper {
    margin-bottom: px(2);

    &:last-child {
      margin-bottom: 0;
    }
  }

  .Radio {
    background-color: color(primary, 500);
    border: 2px solid color(primary, 500);
    border-radius: $border-radius;

    @include transition(border-color);

    &:hover {
      border-color: color(primary, 400);
    }

    label {
      padding: px(2) px(2) px(1.75) px(6);

      color: color(primary);
      font-weight: $font-weight-medium;
      line-height: 16px;

      &::before {
        top: 50%;

        left: px(2);

        background-color: transparent;
        border-color: color(primary);
        border-width: 2px;
        transform: translateY(-50%);
      }

      &::after {
        top: 50%;

        left: px(2);

        width: 8px;
        height: 8px;
        margin: 0 4px;

        background-color: color(primary);
        transform: translateY(-50%);

        content: "";
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .Other {
    padding-left: px(6);
  }
}
