@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 0.2;
	}
}

@keyframes modalFadeIn {
	from {
		opacity: 0;
		transform: translate(-50%, calc(-50% + 50px));
	}

	to {
		opacity: 1;
		transform: translate(calc(-50%), calc(-50%));
	}
}

@keyframes slideInFromRight {
	from {
		transform: translateX(100%);
	}

	to {
		transform: translateX(0);
	}
}
