@import "@Core/assets/styles/abstracts";

.Wrapper {
	display: flex;
	flex-direction: column;
	min-height: 100vh;

	footer {
		margin-top: auto;
	}
}
