@import "@Core/assets/styles/abstracts";

.Container {
  display: flex;
  flex-wrap: wrap;
}

.Bullet {
  flex: 1 1 0;
  max-width: px(4);
  height: px(1);
  margin-right: px(1);

  background-color: color(support, light);
  border-radius: 4px;
}

.BulletActive {
  background-color: color(primary);
}

.ButtonDisabled {
  button {
    cursor: not-allowed !important;
    opacity: 0.4;

    pointer-events: auto !important;
  }
}

.Pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: px(2);
}

.PaginationBullets {
  position: absolute;
  right: 140px;
  left: 140px;

  display: flex;
  align-content: center;
  justify-content: center;
}
