@import "@Core/assets/styles/abstracts";

.ArticlesSearch {
  margin-top: px(3);
}

.Articles {
	position: relative;

	.Placeholder {
		position: relative;
		display: block;
		align-items: flex-start;
		width: 100%;
		max-width: 245px;
		height: 100%;
		padding: px(10) px(2);
		overflow: hidden;

    background-color: color(white);
    border: 2px dashed color(support, light);
    border-radius: $border-radius;
    cursor: pointer;

    user-select: none;

    .PlaceholderIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 75px;
      height: 75px;
      margin-bottom: px(4);

      background-color: color(primary, 400);
      border-radius: 100%;

      @include transition(background-color);
    }

    &:not(.PlaceholderClosed) {
      &:hover {
        .PlaceholderIcon {
          background-color: color(primary, 300);
        }
      }
    }

		&.PlaceholderClosed {
			cursor: not-allowed;
		}
	}

	.Overlay {
		position: absolute;
		inset: 0;
		z-index: 20;
		margin: 0 -1px;
		padding: px(2) 0;
		display: flex;
		align-items: center;
		background-color: rgba(255, 255, 255, 0.5);
	}
}
