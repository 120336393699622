@import "@Core/assets/styles/abstracts";

.ArticlesIntroduction {
  > div {
    padding: px(2) px(8);
    display: flex;
    align-items: center;
  }

  .Introduction {
    width: 480px;
    min-width: 480px;

    > *:last-child {
      margin-bottom: 0;
    }
  }

  .Image {
    margin-left: auto;
    width: 300px;
    min-width: 300px;
    position: relative;
  }

  @include media-breakpoint-down(md) {
    > div {
      padding: px(4);
    }

    .Image {
      display: none;
    }

    .Introduction {
      width: 100%;
      min-width: 100%;
    }
  }
}
