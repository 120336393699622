@import "@Core/assets/styles/abstracts";

.Wrapper {
}

.Items {
  margin-top: px(4);
}

.Search {
  max-width: 520px;
}

.Load {
  margin-top: px(4);
}

.NoResults {
  font-weight: $font-weight-bold;
}
