@import "@Core/assets/styles/abstracts";

.FilePageHeader {
  display: flex;
  align-items: flex-start;

  margin-bottom: px(3);

  @include media-breakpoint-down(md) {
    flex-direction: column;

    .Close {
      display: block;
      width: 100%;
      margin-top: px(2);
      margin-left: 0;
    }
  }

  .People {
    div {
      margin-bottom: pb(2);

      i {
        margin-right: px(0.75);

        font-size: 75%;

        transform: translateY(-10%);
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .Data {
    display: flex;
    flex-wrap: wrap;
    margin-top: px(3);

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }

    div {
      white-space: nowrap;
      margin-right: px(4);

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .Close {
    margin-left: auto;
  }
}
