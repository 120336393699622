@import "@Core/assets/styles/abstracts";

@mixin bar($colors) {
	@each $color in $colors {
		$i: index($colors, $color);

		&:nth-child(#{$i}) {
			background-color: $color;
		}
	}
}

.Segment {
	display: flex;
	cursor: pointer;

	&:hover {
		.Segment__Bars {
			opacity: .75;
		}
	}

	&__Title {
		display: flex;
		width: 100%;
		gap: px(2);
		align-items: center;

		h3 {
			margin-top: 2px;
			margin-bottom: 0;
		}
	}

	&__Bars {
		position: relative;
		mix-blend-mode: 'multiply';

		width: 174px;
		height: 174px;
		min-width: 174px;
		min-height: 174px;
	}

	&__Bar {
		&:nth-child(1) {
			z-index: 5;
			--width: calc(80px + 16px);
		}

		&:nth-child(2) {
			z-index: 4;
			--width: calc(100px + 16px)	
		}

		&:nth-child(3) {
			z-index: 3;
			--width: calc(120px + 16px);
		}

		&:nth-child(4) {
			z-index: 2;
			--width: calc(140px + 16px);
		}

		&:nth-child(5) {
			z-index: 1;
			--width: calc(160px + 16px);
		}

		position: absolute;
		width: var(--width);
		min-width: var(--width);
		height: var(--width);
		min-height: var(--width);
	}

	// Top
	&:nth-child(1),
	&:nth-child(2) {
		flex-direction: column;
		grid-row: 1 / 3;

		.Segment__Title {
			align-self: flex-start;
		}

		.Segment__Bar {
			bottom: 0;
		}
	}

	// Bottom
	&:nth-child(3),
	&:nth-child(4) {
		flex-direction: column-reverse;
		grid-row: 3 / 5;

		.Segment__Title {
			align-self: flex-end;
		}

		.Segment__Bar {
			top: 0;
		}
	}

	//Left
	&:nth-child(1),
	&:nth-child(3) {
		grid-column: 1 / 3;

		.Segment__Bars {
			margin-left: auto;
			display: flex;
			flex-direction: row-reverse;

			padding-right: 32px;
		}

		.Segment__Bar {
			right: 0;
		}
	}

	// Right
	&:nth-child(2),
	&:nth-child(4) {
		grid-column: 3 / 5;

		.Segment__Title {
			flex-direction: row-reverse;
		}

		.Segment__Bars {
			padding-left: 32px;
		}

		.Segment__Bar {
			left: 0;
		}
	}

	&:nth-child(1) {
		.Segment__Bar {
			border-radius: var(--width) 0 0 0;
			@include bar([#FFF6E7, #FFEDCE, #FEE5B6, #FEDC9D, #FED385]);
		}
	}

	&:nth-child(2) {
		.Segment__Bar {
			border-radius: 0 var(--width) 0 0;
			@include bar([#D5DBE6, #AAB6CD, #8092B4, #556D9B, #2B4982]);
		}
	}

	&:nth-child(3) {
		.Segment__Bar {
			border-radius: 0 0 0 var(--width);
			@include bar([#E1DDE8, #C3BBD1, #A598BB, #8776A4, #69548D]);
		}
	}

	&:nth-child(4) {
		.Segment__Bar {
			border-radius: 0 0 var(--width) 0;
			@include bar([#DDF1EF, #BBE4DE, #9AD6CE, #78C9BD, #56BBAD]);
		}
	}
}