@import "@Core/assets/styles/abstracts";

.VideoWrapper {
  width: 100%;

  @include aspect();

  iframe {
    width: 100%;
    height: 100%;
  }
}
