@import "@Core/assets/styles/abstracts";

.Checkbox {
	cursor: pointer;
	position: relative;
	width: 24px;
	height: 24px;
	user-select: none;
	border: 2px solid $input-border-color;
	border-radius: 4px;

	@include transition(border);

	&::after {
		position: absolute;
		content: "\f00c";
		top: -2px;
		left: -2px;
		width: 24px;
		height: 24px;
		color: color(succes, dark);
		font-weight: bold;
		font-family: $font-family-icons;
		text-align: center;
		background-color: transparent;
		border-radius: 4px;
		opacity: 0;

		@include transition(all);
	}

	&:not(.isDisabled):hover {
		border-color: color(succes, dark);

		&::after {
			background-color: color(succes, dark);
			color: color(white);
		}
	}

	&.isChecked {
		border-color: transparent;

		&::after {
			opacity: 1;
		}
	}

	&.isDisabled {
		cursor: not-allowed;
		background-color: $input-border-color;

		&::after {
			color: color(white);
		}
	}
}
