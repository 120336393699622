@import "@Core/assets/styles/abstracts";

.InboxMessage {
  display: flex;
  cursor: pointer;
  margin-bottom: px(2);
  background-color: color(white, 500);
  @include transition(background-color);

  .Content {
    width: 100%;

    .Heading {
      display: flex;
      align-items: center;

      .Title {
        margin-bottom: px(0.5);
        margin-right: px(0.5);
      }

      .Date {
        margin-bottom: 0;
        font-size: $font-size-sm;
      }

      .Toggle {
        min-width: 24px;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        text-align: center;
        font-size: $font-size-xs;
        line-height: 24px;
        background-color: color(primary);
        color: color(white);
        margin-left: auto;
      }
    }

    .Message {
      display: none;
      margin-top: px(2);
    }
  }

  .Notification {
    margin-right: px(2);
    width: 16px;
    min-width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-top: px(2);
    background-color: color(secondary);
  }

  &.isRead {
    background-color: color(white);

    .Title {
      font-weight: $font-weight-light;
    }

    .Notification {
      display: none;
    }
  }

  &.isOpen {
    .Toggle {
      transform: rotate(180deg);
    }

    .Message {
      display: block;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}
