@import "@Core/assets/styles/abstracts";

.StackList {
	display: flex;
	flex-wrap: wrap;
	gap: px(2);

	> :not(:last-child) {
		flex-grow: 1;
		min-width: 210px;
		border-right: 1px solid color(support, light);
		padding-right: px(2);
	}
}
