@import "@Core/assets/styles/abstracts";

.StepRowTag {
	grid-area: 2 / 1 / 3 / 3;
	max-width: max-content;

	@include media-breakpoint-up(md) {
		grid-area: 1 / 3 / 2 / 4;
	}
}
