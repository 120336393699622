@import "@Core/assets/styles/abstracts";

.Article {
  z-index: 1;

  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 297px;

  padding: px(3) px(1) px(2) px(3);
  overflow: hidden;

  background-color: $white;

  .Theme {
    position: relative;

    display: flex;
    align-items: center;
    width: 100%;

    margin-bottom: px(5);
    padding-right: px(1);

    color: color(primary);

    i {
      margin-left: auto;

      color: color(quaternary);
    }
  }

  .New {
    display: inline-block;
    margin-right: px(1);
    padding: 8px 8px 6px 8px;

    color: color(primary);
    font-weight: $font-weight-regular;
    font-size: $font-size-xs;
    white-space: nowrap;

    background-color: color(primary, 400);
    border-radius: 2.4px;
  }

  .Title {
    margin-bottom: auto;
  }

  .Intro {
    font-size: $font-size-sm;
    color: $body-color;
  }

  .Tags {
    position: relative;

    display: flex;
    flex-wrap: nowrap;
    align-items: flex-end;
    width: 100%;
    min-height: 64px;
    overflow: hidden;

    &::after {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 75%;

      display: block;
      height: 32px;

      background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);

      content: "";
      pointer-events: none;
    }
  }

  .Tag {
    display: inline-block;

    margin-right: px(0.5);
    padding: 8px 8px 6px 8px;

    color: color(primary);
    font-weight: $font-weight-regular;
    font-size: $font-size-xs;
    white-space: nowrap;

    background-color: color(primary, 400);
    border-radius: 2.4px;
    cursor: pointer;

    transition-property: background-color, text-shadow;

    @include transition(all);

    &:hover {
      text-shadow: 0.5px 0px 0px color(primary);

      background-color: color(primary, 300);
    }
  }

  .Footer {
    width: 100%;
  }

  .ReadMore {
    z-index: 2;
    margin: px(2) 0 0 -15px;
    padding: 7px 15px 4px;
  }

  &.isLarge {
    height: auto;
    min-height: 0;
    padding: px(4) px(6) px(3);

    .ThemeTag {
      margin-bottom: px(3);
    }

    .Title {
      margin-bottom: px(2);
    }

    .Footer {
      display: flex;
      align-items: center;
      width: 100%;

      .ReadMore {
        margin: 0 0 0 -15px;
      }

      .Tags {
        width: auto;
        min-height: auto;
        margin-left: auto;

        &::after {
          display: none;
        }
      }
    }
  }
}

.ArticleSmall {
  display: flex;
  position: relative;
  overflow: hidden;
  align-items: center;
  border-radius: $border-radius;
  background-color: color(white);
  padding: px(2);

  margin-bottom: px(3);

  &:last-child {
    margin-bottom: 0;
  }

  .Title {
    font-weight: $font-weight-base;
    margin-bottom: 0;
  }

  i {
    @include transition(transform);
  }

  &:hover {
    i {
      transform: translateX(px(0.5));
    }
  }
}
