@import "@Core/assets/styles/abstracts";

.NotesForm {
	> div > div {
		margin-bottom: 0;
	}

	.Input {
		padding: px(1) px(3) px(3);
	}

	.Footer {
		display: flex;
		justify-content: flex-end;
		background-color: color(support, lightest);
		padding: px(2) px(3);
	}
}
