@import "@Core/assets/styles/abstracts";

.Wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 32px;
  min-width: 32px;
  height: 32px;

  color: color(primary);

  font-size: 10px;
  text-align: center;

  background-color: color(support, light);
  border-radius: 100%;

  i {
    font-size: $font-size-xs;
  }
}

.Success {
	color: color(secondary);
	background-color: color(secondary, 600);
}

.Danger {
	color: color(error);
	background-color: color(error, lighter);
}

.Small {
  width: 26px;
  min-width: 26px;
  height: 26px;
}

.Large {
  width: 40px;
  min-width: 40px;
  height: 40px;

  i {
    font-size: $font-size-md;
  }
}

.Secondary {
  color: color(secondary);

  background-color: color(secondary, 600);
}

.Transparent {
	color: color(black);

	background-color: transparent;
}
