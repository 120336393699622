@import "@Core/assets/styles/abstracts";

.Wrapper {
  max-width: 800px;
  margin: 0 auto;
}

.Inputs {
  margin-top: px(3);

  .Radio {
    position: relative;
    z-index: 1;

    margin-bottom: px(2);

    background-color: $white;
    border-radius: $border-radius;

    @include transition(border-color);

    &:hover {
      border-color: color(primary, 400);
    }

    .RadioPercentage {
      position: absolute;
      top: 50%;
      right: px(2);

      color: color(primary);

      font-weight: $font-weight-medium;

      transform: translateY(-43%);
    }

    .RadioBackground {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;

      display: block;

      background-color: color(primary, 500);

      border-radius: $border-radius;

      @include transition(width);
    }

    label {
      padding: px(2) px(2) px(1.75) px(6);

      color: color(primary);
      font-weight: $font-weight-medium;
      line-height: 16px;

      border: 2px solid color(primary, 500);
      border-radius: $border-radius;

      @include transition(border-color);

      &::before {
        top: 50%;

        left: px(2);

        background-color: transparent;
        border-color: color(primary);
        border-width: 2px;
        transform: translateY(-50%);
      }

      &::after {
        top: 50%;

        left: px(2);

        width: 8px;
        height: 8px;
        margin: 0 4px;

        background-color: color(primary);
        transform: translateY(-50%);

        content: "";
      }
    }

    input[type="radio"]:checked + label {
      border-color: color(primary);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.Articles {
  margin-top: px(5);
  margin-bottom: px(-4);

  h4 {
    max-width: 500px;
    margin-bottom: px(3);
  }
}
