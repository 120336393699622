@import "@Core/assets/styles/abstracts";

.StepRowButtons {
	grid-area: 3 / 1 / 4 / 3;
	display: flex;
	flex-direction: column;

	> :not(:first-child) {
		margin-top: px(1);
	}

	@include media-breakpoint-up(md) {
		grid-area: 1 / 4 / 3 / 5;
		align-items: flex-end;
	}
}
