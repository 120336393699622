@use "sass:math";

@mixin box-shadow($value: 1) {
  box-shadow: shadow($value);
}

@mixin transition($attributes, $value: md) {
  transition: $attributes map-get($transitions, $value);
}

@mixin aspect($width: 100%, $ratio: "16:9") {
  $ratios: (
    "1:1": 100%,
    "16:9": 56.25%,
    "4:3": 75%,
    "3:2": 66.66%,
    "8:5": 62.5%,
  );
  position: relative;

  width: $width;
  height: 0;
  padding-top: map-get($ratios, $ratio) * math.div($width, 100%);
  overflow: hidden;

  > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
