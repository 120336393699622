@import "@Core/assets/styles/abstracts";

.Aspect {
  @include media-breakpoint-only(xl) {
    overflow: visible !important;

    @include aspect(100%, "1:1");
  }

  @include media-breakpoint-down(lg) {
    position: relative;
  }
}

.Wrapper {
  display: flex;
  flex-direction: column;

  img {
    max-width: 200px;
    margin-top: px(1);
    margin-bottom: px(3);
  }

  @include media-breakpoint-only(xl) {
    padding-bottom: 0;

    .Button {
      position: absolute;
      bottom: 0;
      left: 0;

      z-index: 1;
    }
  }
}

.Header {
  display: flex;

  .Points {
    margin-left: auto;

    color: color(quaternary);

    strong {
      margin-right: px(0.5);

      font-size: $font-size-lg;
    }
  }
}

.Progress {
  position: absolute;
  top: px(2);
  right: px(4);

  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  .HappySocks {
    padding: 8px;
    padding-right: 0;

    cursor: pointer;
  }
}

.Success {
  .Points {
    color: color(succes);

    &::before {
      margin-right: px(1);

      font-weight: normal;
      font-size: $font-size-h2;
      font-family: $font-family-icons;

      content: "\f00c";
    }
  }
}
