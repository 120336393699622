@import "@Core/assets/styles/abstracts";

.Popup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 101;

  visibility: hidden;
}

.Body {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 101;

  width: 90%;
  max-width: 560px;
  padding: px(3) px(2);

  background-color: color(white);

  border-radius: $border-radius;
  box-shadow: box-shadow();
  transform: translate(-50%, -100%);
  visibility: hidden;
  opacity: 0;

  @include transition(all, lg);
}

.Header {
  display: flex;
  align-items: center;
  margin-bottom: px(2);

  div {
    margin-right: px(1);
  }

  h5 {
    margin-bottom: 0;

    color: color(primary);
  }
}

.Close {
  position: absolute;
  top: 6px;
  right: 0;

  color: color(primary, 200);
  font-size: $font-size-base;

  border-radius: 0;
}

.Content {
  background-color: color(white, 300);

  @include aspect();
}

.Background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;

  background-color: color(black);
  visibility: hidden;
  cursor: pointer;
  opacity: 0;

  @include transition(opacity);
}

.PopupVisible {
  visibility: visible;

  .Body {
    transform: translate(-50%, -50%);
    visibility: visible;
    opacity: 1;
  }

  .Background {
    visibility: visible;
    opacity: 0.5;
  }
}
