@import "@Core/assets/styles/abstracts";

.Overlay {
	position: fixed;
	inset: 0;
	z-index: 1000;
	background: color(black);
	animation: fadeIn 200ms both;
}

.Sidebar {
	position: fixed;
	inset: 0 0 0 auto;
	z-index: 1000;
	width: 585px;
	max-width: calc(100% - 40px);
	padding: 30px;
	background-color: color(white);
	overflow-y: auto;
	box-shadow: $box-shadow;
	animation: slideInFromRight 200ms both;

	.Header {
		.Close {
			color: color(primary, 100);
			font-weight: $font-weight-medium;
			display: flex;
			align-items: center;
			margin-bottom: px(5);
			cursor: pointer;

			.Icon {
				margin-right: px(1.5);
				transform: translateY(-2px);
			}

			&:hover {
				.Label {
					text-decoration: underline;
				}
			}
		}

		.Title {
			color: color(primary, 100);
			font-size: $font-size-lg;
			font-style: italic;
			font-weight: $font-weight-medium;
			line-height: 1;
			margin-bottom: 0;
		}
	}

	.Content {
		> *:last-child {
			margin-bottom: 0;
		}
	}
}
