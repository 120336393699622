@import "@Core/assets/styles/abstracts";

.ArticlesDetailHeader {
  .Block {
    padding: 0;
    margin-bottom: px(6);

    > div {
      padding: px(8) 0 px(5) 0;

      @include media-breakpoint-down(md) {
        padding-left: px(4);
        padding-right: px(4);
      }
    }
  }
  .Title {
    max-width: 720px;
  }

  .Intro {
    max-width: 480px;
    margin-bottom: 0;
    font-weight: 300;
  }

  .Footer {
    display: flex;
    align-items: center;
    margin-top: px(4);

    .Time {
      font-size: $font-size-sm;
      margin-right: px(3);
      white-space: nowrap;
      color: color(primary);

      i {
        margin-right: px(1);
      }
    }

    .Tags {
      margin-bottom: px(-1);

      > div {
        margin-bottom: px(1);
      }
    }

    .Share {
      margin-left: auto;
      padding-right: px(4);
    }

    @include media-breakpoint-down(md) {
      flex-direction: column;
      align-items: flex-start;

      .Tags {
        margin: px(1.5) 0;
      }

      .Share {
        margin-left: 0;
      }
    }
  }
}
