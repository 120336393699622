@import "@Core/assets/styles/abstracts";

.Inputs {
  .Card {
    height: 100%;
    margin-bottom: px(2);

    border: 2px solid color(primary, 500);
    border-radius: $border-radius;
    box-shadow: $box-shadow;

    @include transition(border-color);

    &:hover {
      border-color: color(primary, 400);
    }

    label {
      display: flex;
      align-items: center;
      height: 100%;
      padding: px(3) px(2);

      color: color(primary);
      font-weight: $font-weight-medium;
      line-height: 16px;

      i {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 56px;
        min-width: 56px;
        height: 56px;
        min-height: 56px;
        margin-right: px(3);

        font-size: 24px;

        background-color: color(white, 200);
        border-radius: $border-radius;
      }

      h5 {
        margin-bottom: 0;
      }

      &::before {
        display: none;
      }

      &::after {
        top: -2px;
        right: -2px;
        bottom: -2px;
        left: -2px;

        width: calc(100% + 4px);
        height: calc(100% + 4px);

        border: 2px solid color(primary);

        border-radius: $border-radius;

        content: "";
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
