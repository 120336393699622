@import "@Core/assets/styles/abstracts";

.FilePageHeader {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-top: px(2);
	margin-bottom: px(4);

	&__Title {
		color: color(primary);
		margin-bottom: px(3);
		font-size: $font-size-h1;
		font-weight: $font-weight-medium;

		small {
			display: block;
			color: $body-color;
			font-size: $font-size-xs;
			font-weight: $font-weight-bold;
			text-transform: uppercase;
		}
	}

	&__Content {
		max-width: 726px;
		margin-bottom: px(4);
	}

	&__Buttons {
		display: flex;
		gap: px(2);
		align-items: flex-end;
		flex-direction: column;

		button {
			min-width: 185px;
			justify-content: center;
		}

		@include media-breakpoint-down(md) {
			margin-top: px(4);

			div, button {
				width: 100%;
			}
		}
	}

  @include media-breakpoint-down(md) {
    flex-direction: column;

    .Close {
      display: block;
      width: 100%;
      margin-top: px(2);
      margin-left: 0;
    }
  }

  .People {
    div {
      margin-bottom: pb(2);

      i {
        margin-right: px(0.75);

        font-size: 75%;

        transform: translateY(-10%);
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .Data {
    display: flex;
    flex-wrap: wrap;
    margin-top: px(3);

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }

    div {
      white-space: nowrap;
      margin-right: px(4);

      &:last-child {
        margin-right: 0;
      }
    }
  }

	.Close {
		margin-left: auto;
	}
}