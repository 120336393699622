@import "@Core/assets/styles/abstracts";

.Inputs {
  .Number {
    color: color(primary);
    font-size: $font-size-base;

    background-color: color(primary, 500);
    border: 2px solid color(primary, 500);
    border-radius: $border-radius;

    @include transition(border-color);

    &:focus,
    &:hover {
      border-color: color(primary, 400);
    }
  }
}
