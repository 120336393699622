@import "@Core/assets/styles/abstracts";

.Sections {
  display: flex;
  margin-bottom: px(3);

  .SectionWrapper {
    position: relative;

    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: auto;

    &::after {
      position: absolute;
      right: 12.5px;
      left: 0;

      display: block;

      border-top: 2px dashed color(support);

      content: "";
    }
  }

  .Section {
    position: relative;
    z-index: 1;

    margin-right: px(1);
    margin-left: px(1);

    border-radius: 100%;
    box-shadow: $box-shadow;

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      background-color: color(support, lightest);
      border-radius: 100%;

      content: "";
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    img {
      position: relative;
      z-index: 1;

      width: 50px;
      height: 50px;
      object-fit: contain;

      border-radius: 50%;

      opacity: 0.2;
      filter: grayscale(100%);

      @include transition(all);
    }

    &.SectionDone::after {
      position: absolute;
      right: -8px;
      bottom: 0;
      z-index: 2;

      display: block;

      width: 20px;
      height: 20px;

      color: color(white);
      font-size: $font-size-xs;
      font-family: $font-family-icons;
      line-height: 22px;
      text-align: center;

      background-color: color(succes);
      border-radius: 20px;

      content: "\f00c";
    }

    &.SectionDone,
    &.SectionCurrent {
      img {
        opacity: 1;
        filter: none;
      }
    }
  }
}

.Questionnaire {
  margin-bottom: px(4);

  .Content {
    position: relative;
    z-index: 1;

    display: flex;
    justify-content: space-between;

    @include media-breakpoint-down(sm) {
      flex-direction: column;

      .Image {
        flex: 0 0 100% !important;
        margin-top: px(3);
        margin-left: 0 !important;
      }
    }

    .Image {
      flex: 0 0 300px;
      margin-left: px(6);

      img {
        width: 100%;
      }
    }
  }

  .Action {
    user-select: none;


    .Header {
      display: flex;
      align-items: center;

      margin-top: px(-3);
      margin-bottom: px(-3);

      padding-top: px(3);
      padding-bottom: px(3);

      cursor: pointer;

      .Title {
        margin-bottom: 0;
        margin-left: px(1.5);

        transform: translateY(2px);
      }

      .Toggle {
        margin-left: auto;
      }
    }

    .Body {
      margin-top: px(2);

      .Items {
        position: relative;

        margin-top: px(3);
        padding-top: px(3);

        border-top: 1px solid color(support, light);

        .Urgency {
          position: absolute;
          top: 10px;
          right: 0;

          width: 8px;
          min-width: 8px;
          height: 8px;
        }

        .Item {
          position: relative;

          display: flex;
          flex-direction: row;

          margin-bottom: px(2);

          .Urgency {
            position: absolute;
            top: 18px;
            right: px(2);
          }

          &:last-child {
            margin-bottom: 0;
          }
        }

        .ItemContent {
          div > *:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    &.ActionActive {
      .Header {
        .Toggle {
          margin-top: -2px;

          transform: rotate(180deg);
          transform-origin: center;
        }
      }
    }
  }

  .Progress {
    margin: 0 auto;
    margin-bottom: px(6);
    padding-bottom: px(6);

    border-bottom: 1px solid color(support, light);

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;

      border-bottom: 0;
    }
  }

  .Additional {
    background-color: color(primary);
    border-color: color(primary);

    &__Body {
      display: flex;
      align-items: center;
      margin-top: px(-3);
      margin-bottom: px(-3);
      padding-top: px(3);
      padding-bottom: px(3);

      color: color(white);

      cursor: pointer;

      h5 {
        margin-bottom: 0;
        padding-right: px(1);
        overflow: hidden;

        color: color(white);

        white-space: nowrap;
        text-overflow: ellipsis;
      }

      i {
        margin-left: auto;
      }
    }

    &--Completed {
      background-color: color(primary, 400);
      border-color: color(primary, 400);

      h5,
      i {
        color: color(primary);
      }
    }
  }

  .Buttons {
    align-items: center;
    margin-top: px(4);

    .Steps {
      margin-right: auto;
      margin-left: auto;
    }

    button:last-child {
      margin-left: 0;
    }
  }
}

@include media-breakpoint-down(sm) {
  .Questionnaire {
    .Buttons {
      flex-direction: column;

      button {
        justify-content: center;
      }

      .Steps {
        order: 3;
        margin-top: px(4);
      }
    }
  }
}
