@import "@Core/assets/styles/abstracts";

.ArticlesArchive {
  margin-bottom: px(3);

  .Title {
    text-transform: uppercase;
  }

  .Filters {
    display: flex;
    align-items: center;
    gap: px(0.5);
    margin-bottom: px(2);

    .Label {
      font-size: $font-size-sm;
      margin-right: px(1);
    }
  }

  @include media-breakpoint-down(md) {
    .Row {
      flex-direction: column-reverse;
    }

    .Sidebar {
      margin-bottom: px(4);
    }
  }
}
