@import "@Core/assets/styles/abstracts";

.Tag {
	display: inline-block;
	margin-right: px(1);
	padding: px(1) px(1) 6px px(1);
	color: color(primary);
	font-weight: $font-weight-regular;
	font-size: $font-size-xs;
	white-space: nowrap;
	border-radius: 2.4px;
	transition-property: background-color, text-shadow;
	background-color: color(primary, 400);

  @include transition(all);

	&--variant-light {
		background-color: color(support, lightest);
	}

	&.hasIcon {
		font-size: $font-size-sm;

		i {
			font-size: $font-size-xs;
			margin-right: px(0.75);
			transform: translateY(-1px);
		}
	}

	&.isActive,
	&.hasOnClick:hover {
		cursor: pointer;

		text-shadow: 0.5px 0px 0px color(primary);

    background-color: color(primary, 300);
  }

  &.isLarge {
    padding: px(1) px(1.5) 6px;
    font-size: $font-size-sm;
  }
}
