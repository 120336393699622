@import "@Core/assets/styles/abstracts";

.Wrapper {
  position: relative;
  z-index: 1;

  padding-top: px(2);
  padding-bottom: px(2);

  background-color: color(white);
}

.TogglerContainer {
  position: absolute;
  cursor: pointer;
  right: px(2);
  top: 0;
  display: flex;

  .Toggler {
    transform: translateY(-50%);
    margin-left: px(0.5);
    width: 40px;
    height: 40px;
    border-radius: 40px;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: color(primary);
    color: color(white);
  }
}

.Container {
  display: flex;

  @include media-breakpoint-down(sm) {
    flex-direction: column-reverse;
  }
}

.Disclaimer {
  margin-left: auto;

  @include media-breakpoint-down(sm) {
    display: flex;
    flex-direction: column;

    margin-bottom: px(1);
    margin-left: 0;

    a {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
  }

  a {
    padding-right: px(1.5);
    padding-left: px(1.5);

    color: color(black);

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}
