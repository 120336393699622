@import "@Core/assets/styles/abstracts";

.Wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  footer {
    margin-top: auto;
  }
}

.Body {
  padding-top: px(2);
}

.Breadcrumbs {
  display: flex;
  align-items: center;
  padding-top: px(4);

  span,
  a {
    color: color(support);
    font-size: $font-size-sm;
  }

  a:hover {
    color: color(support, dark);
  }

  i {
    margin: 0 px(1);

    color: color(support);
    font-size: $font-size-xs;
  }
}
