@import "@Core/assets/styles/abstracts";

.Loader {
  position: relative;

  margin-left: auto;
  margin-right: auto;

  &:before {
    display: block;
    padding-top: 100%;

    content: "";
  }

  svg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    z-index: 1 !important;

    width: 100%;
    height: 100%;
    margin: auto;

    transform-origin: center center;

    animation: rotate 2s linear infinite;
  }

  circle {
    animation: dash 1.5s ease-in-out infinite;

    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    stroke-linecap: round;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
