@import "@Core/assets/styles/abstracts";

.Countdown {
  position: relative;

  display: block;
  height: 80px;
  margin-bottom: px(3);

  circle {
    stroke: #fff6e4;
    stroke-width: 8px;
    stroke-linecap: round;
  }

  .Percentage {
    transform: rotate(-90deg);
    transform-origin: center;

    transition: 0.2s stroke-dashoffset ease-in-out;

    stroke: #ffcb6b;
  }

  .Center {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;

    color: $body-color;

    font-size: $font-size-sm;

    line-height: 1.1;

    text-align: center;

    transform: translateY(-50%);

    i,
    > div {
      font-size: $font-size-xs;
    }

    i {
      margin-bottom: px(0.5);
    }
  }

  .Content {
    position: absolute;
    top: 50%;
    left: 90px;

    color: $body-color;

    font-size: $font-size-xs;

    line-height: 1.2;

    white-space: nowrap;

    transform: translateY(-50%);

    strong {
      display: block;
    }
  }
}
