@import "@Core/assets/styles/abstracts";

.Frame {
	background-color: color(white);
	border: 1px solid color(support, light);
	width: 100%;
	border-radius: $border-radius;
	overflow: hidden;

	&--variant-light {
		background-color: color(support, lightest);
	}

	&--variant-primary {
		background-color: color(primary, 400);
		border-color: color(primary, 400);
	}

	&--elevated-small {
		box-shadow: $box-shadow;
	}

	&--elevated-large {
		box-shadow: 12px 24px 24px 0 rgba(13, 38, 53, 0.08);
	}
}

.FrameBody {
	&--padding-2 {
		padding: px(2) px(3);
	}

	&--padding-3 {
		padding: px(3);
	}

	&--padding-4 {
		padding: px(4);
	}
}

.FrameExpand {
	display: flex;
	cursor: pointer;
	color: color(primary);
	font-weight: $font-weight-medium;
	align-items: center;
	justify-content: center;
	height: 72px;
	background-color: color(support, lightest);
	padding-left: px(3);
	padding-right: px(3);

	&:hover {
		text-decoration: underline;
	}
}
