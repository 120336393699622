@import "@Core/assets/styles/abstracts";

.ArticlesArchiveFilter {
  .Block {
    background-color: color(primary, 500);
    border-radius: $border-radius;
    padding: px(3);
    margin-bottom: px(4);

    &:last-child {
      margin-bottom: 0;
    }
  }

  .Themes {
    .Button {
      display: flex;
      text-align: left;
      width: 100%;

      padding-top: px(1);
      padding-bottom: px(1);

      i {
        margin-left: auto;
      }
    }
  }

  .Keywords {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: px(1);

    height: 115px;
    overflow: hidden;

    &.isOpen {
      height: auto;
    }

    > div {
      margin-right: 0;
      flex-grow: 1;
      text-align: center;
    }
  }

  .Toggle {
    display: none;
  }

  @include media-breakpoint-down(md) {
    .Toggle {
      display: block;
    }

    .Filters {
      height: 0;
      overflow: hidden;

      &.isOpen {
        margin-top: px(3);
        height: auto;
      }
    }
  }
}
