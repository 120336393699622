@import "@Core/assets/styles/abstracts";

.Inputs {
  .Titles {
    display: flex;
    justify-content: space-between;
    margin-bottom: px(2);

    font-size: $font-size-md;
  }

  .Numbers {
    display: flex;
    flex-wrap: wrap;

    margin-right: px(-1);
    margin-left: px(-1);
    margin-bottom: px(-2);

    .Number {
      flex: 1 1 0;
      margin: 0 px(1) px(2) px(1);

      background-color: color(primary, 500);
      border: 2px solid color(primary, 500);
      border-radius: $border-radius;

      @include transition(border-color);

      &:hover {
        border-color: color(primary, 400);
      }

      label {
        height: 42px;
        padding: 0 px(1);

        color: color(primary);
        font-weight: $font-weight-bold;
        font-size: $font-size-md;

        line-height: 47px;

        white-space: nowrap;
        text-align: center;

        &::before {
          top: -2px;
          right: -2px;
          bottom: -2px;
          left: -2px;

          width: unset;
          height: unset;

          background-color: transparent;
          border-color: transparent;
          border-width: 2px;
          border-radius: $border-radius;

          @include transition(border-color);
        }

        &::after {
          display: none;
        }
      }

      input:checked + label::before {
        border-color: color(primary);
      }
    }
  }
}
