@import "@Core/assets/styles/abstracts";

.Text {
  .Wrapper {
    max-width: 600px;

    p {
      margin-bottom: px(2);
    }

    > *:last-child {
      margin-bottom: 0;
    }
  }
}

.TextImage {
  .Image {
    max-width: 300px;
    width: 100%;

    margin-right: px(6);
  }

  .Wrapper {
    display: flex;
    max-width: 100%;

    h5 {
      font-size: $h2-font-size;
    }
  }

  @include media-breakpoint-down(sm) {
    .Wrapper {
      flex-direction: column;
    }

    .Image {
      margin: 0 auto px(4) auto;
    }
  }
}
