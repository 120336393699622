@import "@Core/assets/styles/abstracts";

.Wrapper {
  display: inline-flex;
  align-items: center;

  vertical-align: middle;
}

.Prefix {
  color: color(secondary);
}

.Name {
  position: relative;

  margin-left: px(1.5);

  color: color(black);
  font-weight: bold;
  font-size: $font-size-h3;
  line-height: 1.05;
  white-space: nowrap;
}

.Logo {
  height: 48px;
}

.Copyright {
  position: absolute;
  bottom: px(-2);

  color: color(tertiary);
  font-weight: 300;
  font-size: $font-size-xs;

  opacity: 0.4;
}
