@import "@Core/assets/styles/abstracts";

.Circle {
  position: absolute;

  transform: translate(-50%, -50%);

  user-select: none;
  pointer-events: none;
}
