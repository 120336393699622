@import "@Core/assets/styles/abstracts";

.Pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 32px;
  margin: 0;

  li {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;

    &::before {
      display: none;
    }

    a {
      outline: 0;
      cursor: pointer;
    }
  }
}

.PaginationPrevious,
.PaginationNext {
  a {
    color: color(primary);
    font-size: $font-size-sm;

    i {
      width: 13px;
      margin-left: px(1);
    }
  }
}

.PaginationPrevious {
  margin-right: px(1);

  a {
    i {
      margin-right: px(1);
      margin-left: 0;
    }
  }
}

.PaginationNext {
  margin-left: px(1);
}

.PaginationNumber {
  a {
    display: block;

    width: 18px;
    height: 18px;
    margin: 0 2px;

    color: $body-color;
    font-size: $font-size-xs;
    line-height: 20px;
    text-align: center;

    border-radius: 50%;

    @include transition(background-color);

    &:hover {
      background-color: color(primary, 500);
    }
  }
}

.PaginationNumberActive {
  a {
    width: 32px;
    height: 32px;

    color: color(primary);
    font-weight: $font-weight-medium;
    font-size: $font-size-base;
    line-height: 36px;

    background-color: color(primary, 500);
  }
}

.PaginationBreak {
  a {
    color: #494949;
    font-size: $font-size-xs;
  }
}
