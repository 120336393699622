@import "@Core/assets/styles/abstracts";

.Wrapper {
  margin-top: px(3);
  padding: px(4);

  background-color: color(white);
  border: solid 1px color(secondary, 600);
  border-radius: $border-radius;

  h4 {
    display: flex;
    align-items: center;
    margin-bottom: px(4);

    div {
      margin-right: px(1.5);
    }
  }

  .Content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    ol,
    ul,
    p {
      margin-bottom: 0;
    }

    div {
      width: 100%;
      margin-top: px(3);
      margin-left: 0;
      padding: px(3);

      background-color: color(primary, 500);
      border-radius: $border-radius;

      strong {
        display: block;
        margin-bottom: px(2.5);

        color: color(black);
      }

      ul {
        li {
          padding-left: 0;

          &::before {
            display: none;
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  .Wrapper {
    padding-right: px(3);
    padding-left: px(3);
  }
}
