@import "@Core/assets/styles/abstracts";

.Wrapper {
  position: relative;

  display: block;
  max-width: 715px;
  margin: 0 auto;
}

.Title {
  display: flex;

	u {
		margin-left: 5px;
		margin-right: 5px;
	}
}

.Answers {
  margin-top: px(2);
}
