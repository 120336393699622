@import "@Core/assets/styles/abstracts";

.Wrapper {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  min-height: 315px;

  padding: px(7) px(8) px(5) px(8);
  overflow: hidden;

  background-color: color(primary, 500);
  border-radius: $border-radius * 2;

  h1 {
    padding-right: px(3);

    @include media-breakpoint-down(md) {
      font-size: $font-size-h4;
    }
  }

  p {
    max-width: 480px;
    margin-bottom: 0;

    font-size: $font-size-md;
  }
}

@include media-breakpoint-down(md) {
  .Wrapper {
    min-height: 0;
    padding: px(6) px(4) px(5) px(4);

    h1 {
      font-size: $font-size-lg;
    }
  }
}

@include media-breakpoint-down(sm) {
  .Wrapper {
    padding: px(6) px(3) px(5) px(3);
  }
}
