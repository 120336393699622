@import "@Core/assets/styles/abstracts";

.Complete {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(color(white), 0.5);

  .CompleteContent {
    max-width: 400px;
    padding: 30px;

    background-color: color(white);
    border-radius: $border-radius;

    box-shadow: $box-shadow;
  }
}

.Challenges {
  @include media-breakpoint-down(sm) {
    > div:last-child {
      display: none;
    }
  }
}
