@import "@Core/assets/styles/abstracts";

.Wrapper {
  @include aspect(100%, "16:9");
  margin-bottom: px(3);

  > * {
    width: 100% !important;
    height: auto !important;
  }
}
