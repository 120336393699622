@import "@Core/assets/styles/abstracts";

.Wrapper {
  display: flex;
  align-items: center;
}

.ProgressBar {
  overflow: hidden;
  width: 120px;

  height: 4px;
  margin-right: px(1.5);

  background-color: color(support, light);
  border-radius: 2px;

  .Progress {
    height: 4px;

    background-color: color(succes);
    border-radius: 2px;
  }
}

.ProgressSteps {
  display: flex;
  width: 210px;
  height: 8px;

  margin-right: px(1.5);

  .Step {
    display: block;
    flex: 1 1 0;
    width: 0;
    height: 8px;
    margin-right: px(1);

    background-color: color(support, light);
    border-radius: 4px;

    transition-property: background-color, transform;

    @include transition(background-color);

    &:last-child {
      margin-right: 0;
    }

    &.Active {
      background-color: color(secondary, 200);
    }

    &.Current {
      background-color: color(secondary);
    }
  }
}

.ProgressPoints {
  background-color: color(quaternary, lighter);

  .Progress {
    background-color: color(quaternary);
  }
}

.Points {
  flex-direction: column-reverse;
  align-items: flex-start;

  .ProgressBar {
    border-radius: 10px;
    height: 10px;

    background-color: color(quaternary, lighter);

    .Progress {
      border-radius: 10px;
      height: 10px;

      background-color: color(quaternary);
    }
  }

  .Title {
    color: color(black);

    .Step {
      font-weight: bold;
      font-size: $font-size-h2;
    }

    .Steps {
      font-weight: $font-weight-base;
      font-size: $font-size-base;
    }

    .Subtitle {
      font-size: $font-size-xs;
    }
  }
}

.Dots {
  .ProgressSteps {
    width: auto;
  }

  .Step {
    width: 8px;
  }
}

.Title {
  color: color(primary);
  font-size: $font-size-sm;
  white-space: nowrap;
}
