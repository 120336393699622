@import "@Core/assets/styles/abstracts";

.Select {
  position: relative;

  select {
    padding-right: 30px;

    background-color: color(primary, 500);
    border: 2px solid color(primary, 500);
    border-radius: $border-radius;

    @include transition(border-color);

    &:hover {
      border-color: color(primary, 400);
    }
  }

  &::after {
    position: absolute;
    top: 50%;
    right: 20px;

    font-size: 16px;
    font-family: "Font awesome 5 Pro";

    transform: translate(0, -50%);

    content: "\f078";
    pointer-events: none;
  }
}
