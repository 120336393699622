@import "@Core/assets/styles/abstracts";

.ArticlesArchiveList {
  position: relative;
  background-color: color(white);
  border-radius: $border-radius;
  padding: px(3);

  &::after {
    position: absolute;
    content: "";
    inset: 0;
    background-color: color(white);
    visibility: hidden;
    opacity: 0;
    z-index: 10;

    user-select: none;

    @include transition(opacity);
  }

  &.isLoading {
    &::after {
      opacity: 0.75;
      visibility: visible;
    }
  }
}
