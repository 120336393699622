@import "@Core/assets/styles/abstracts";

.RiskProfileMatrix {
	min-width: 352px;
	display: grid;
	grid-template-columns: 1fr 64px 64px 1fr;
	grid-template-rows: 1fr 64px 64px 1fr;

	&__Center {
		position: relative;
		z-index: 20;
		grid-row: 2 / 4;
		grid-column: 2 / 4;

		&::after {
			position: absolute;
			display: block;
			content: "";
			inset: px(-2);
			z-index: -5;
			background-color: color(support, lightest);
			border-radius: 50%;
		}
	}

	&__LineX,
	&__LineY {
		position: relative;
		z-index: 6;

		background-color: color(support, lightest);

		&::after {
			content: "";
			position: absolute;
			display: block;
			background-color: color(white);
	
		}
	}

	&__LineX {
		grid-column: 1 / 5;
		grid-row: 3 / 4;

		transform: translateY(-50%);
		height: px(2);
		width: 100%;

		&::after {
			height: 2px;
			left: 0;
			top: 50%;
			right: 0;
			transform: translateY(-50%);

		}
	}

	&__LineY {
		grid-column: 3 / 4;
		grid-row: 1 / 5;

		transform: translateX(-50%);
		height: 100%;
		width: px(2);

		&::after {
			width: 2px;
			bottom: 0;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
	
		}
	}
}
