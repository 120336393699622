@import "@Core/assets/styles/abstracts";

.Button {
  @include media-breakpoint-down(sm) {
    position: relative;
    top: auto;
    right: auto;

    display: block;
    width: 100%;
  }
}
