@import "@Core/assets/styles/abstracts";

.Wrapper {
  position: relative;

  display: flex;
  align-items: center;

  height: 100%;

  margin-bottom: px(2);
  overflow: hidden;

  background-color: color(white);
  border: 2px solid color(support, light);
  border-radius: $border-radius;

  transform: translateY(0);
  cursor: pointer;

  transition-property: box-shadow border transform;

  @include transition(all);

  &:not(.DisabledLight):hover {
    border: 2px solid color(support, lightest);
    box-shadow: $box-shadow;
    transform: translateY(-2px);
  }

	.Column {
		display: flex;
		align-items: center;
		margin-right: px(2);
	}

  .Type {
    width: 80px;
    min-width: 80px;
    margin-left: px(2);

    color: color(primary);
    font-weight: normal;
    white-space: nowrap;
    text-transform: capitalize;

    @include media-breakpoint-only(lg) {
      display: none;
    }
  }

  .Content {
    .Title {
      margin-bottom: 0;

      i {
        font-size: 75%;
        transform: translateY(-10%);
        margin-right: px(0.75);
      }

      @include media-breakpoint-down(sm) {
        text-align: center;
      }
    }

    .Title + .Description {
      margin-top: px(1);
    }
  }

  .Step,
  .Button {
    margin-left: px(3);

    @include media-breakpoint-down(md) {
      margin-left: 0;
    }
  }

  .Button {
    @include media-breakpoint-down(md) {
      width: 100%;

      > button {
        display: block;
        width: 100%;
        max-width: 100%;
        overflow: hidden;

        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .Data {
    margin-left: px(3);

    @include media-breakpoint-down(md) {
      margin-left: 0;
    }

    i {
      margin-right: px(0.75);

      color: color(support);
    }
  }

  &.Default,
  &.Small {
    padding: px(2);

    @include media-breakpoint-down(md) {
      .Type {
        display: none;
      }
    }

    @include media-breakpoint-down(md) {
      flex-direction: column;
      align-items: center;

      .Column {
        margin-right: auto !important;
        margin-left: auto !important;
      }

      .Content {
        margin: px(2) 0;

        text-align: center;
      }

      .Type {
        display: block;
      }
    }
  }

  &.Large {
    padding: px(4) px(2);

    @include media-breakpoint-down(lg) {
      align-items: center;
    }

    @include media-breakpoint-down(md) {
      flex-direction: column;
      align-items: flex-start;
      padding: px(4);

      .Content {
        margin-top: px(3);
        margin-bottom: px(1);
        margin-left: 0;
      }
    }
  }

  &.Disabled {
    opacity: 0.5;
    filter: blur(2px);

    user-select: none;
    pointer-events: none;
  }

  &.DisabledLight {
    cursor: not-allowed;
  }

  &.Dotted {
    border-style: dashed;
  }

  &.Finished {
    border-color: color(secondary);

    .Type {
      color: color(secondary);
    }
  }
}
