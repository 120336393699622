@import "@Core/assets/styles/abstracts";

.QuestionnaireScore {
  .Score {
    margin-bottom: px(2);

    &:last-child {
      margin-bottom: 0;
    }

    &__Theme {
      display: flex;
      align-items: center;
    }

    &__ThemeName {
      font-weight: $font-weight-bold;
    }

    &__ThemeScore {
      width: 50px;
      height: 50px;
      margin-right: px(2);

      color: color(primary);
      font-weight: $font-weight-bold;
      line-height: 53px;
      text-align: center;

      background-color: color(primary, 500);
      border-radius: 50px;
    }

    &__Description {
      margin-left: 68px;
    }
  }
}
