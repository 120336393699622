@import "@Core/assets/styles/abstracts";

.SeperatedList {
	.Item {
		margin: 0;
		padding: px(2) 0;
		border-bottom: 1px solid color(support, light);

		&:first-child {
			padding-top: 0;
		}

		&:last-child {
			padding-bottom: 0;
			border-bottom: none;
		}
	}
}
